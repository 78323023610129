import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  AuthErrorCodes,
  updateProfile,
  onAuthStateChanged,
} from "firebase/auth";
import AuthLayoutPage from "../../layouts/AuthLayoutPage";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { auth } from "../../utils/firebase";
import { FirebaseError } from "firebase/app";
import { userStore } from "../../store/userStore";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as HideEyeIcon } from "../../assets/icons/hide-icon.svg";
import { ReactComponent as Google } from "../../assets/icons/google-svg.svg";
import Logo from "../../assets/logo/ubu-con-logo-2.png";

const initialValues = { name: "", email: "", password: "" };

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const updateUser = userStore((state) => state.updateUser);
  const updateFetching = userStore((state) => state.updateFetching);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .max(20, "Name must be at most 20 characters")
      .required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  const handleGoogleMethod = async () => {
    try {
      const Provider = new GoogleAuthProvider();
      const data = await signInWithPopup(auth, Provider);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    return onAuthStateChanged(auth, (data) => {
      if (data) {
        updateUser(data);
        setTimeout(() => {
          updateFetching(false);
          navigate("/company");
        }, 0);
      } else {
        setTimeout(() => {
          updateFetching(false);
          navigate("/signup");
        }, 0);
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      await updateProfile(user, { displayName: values.name });
      navigate("/login");
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
          setErrorMessage(
            "Email is already in use. Please choose a different email."
          );
          setErrorMessage(
            "Email is already in use. Please choose a different email."
          );
        }
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <AuthLayoutPage>
            <div className="login-container">
              {errorMessage && (
                <div className="error-window">
                  <p>{errorMessage}</p>
                </div>
              )}
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>
              <div className="login-wrapper flex-content">
                <div>
                  <div className="login-text">Create an account</div>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Enter your name"
                    label="Name"
                  />

                  <Input
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    label="E-mail"
                  />

                  <Input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    label="Password"
                    icon={
                      showPassword ? (
                        <EyeIcon
                          width={14}
                          height={14}
                          cursor="pointer"
                          onClick={handleTogglePassword}
                        />
                      ) : (
                        <HideEyeIcon
                          width={14}
                          height={14}
                          cursor="pointer"
                          onClick={handleTogglePassword}
                        />
                      )
                    }
                  />

                  <Button
                    varient="primary"
                    type="submit"
                    disabled={isSubmitting}
                    style={{ opacity: isSubmitting ? "0.6" : "" }}
                  >
                    {isSubmitting ? "Creating..." : "Create account"}
                  </Button>
                  <div className="line-border">
                    <div className="border"></div>
                    <p>or</p>
                    <div className="border"></div>
                  </div>
                  <div className="google-content">
                    <Google />
                    <p onClick={handleGoogleMethod}>Sign up with google</p>
                  </div>
                  <div className="sign-up-content">
                    <p>Already have an account?</p>
                    <Link to="/login">Login</Link>
                  </div>
                </div>
              </div>
            </div>
          </AuthLayoutPage>
        </Form>
      )}
    </Formik>
  );
};

export default SignUp;
