import { create } from "zustand";

interface IUserState {
  user: string;
  setUser: (user: string) => void;
}

export const useUserStore = create<IUserState>((set) => ({
  user: "",
  setUser: (user) => set({ user }),
}));
