import constructionOne from "../../assets/images/construction-img.png";
import constructionTwo from "../../assets/images/construction-women-img2.png";
import constructionthree from "../../assets/images/construction-building-img-3.png";
import constructionfour from "../../assets/images/construction-img-4.png";
import constructionfive from "../../assets/images/construction-img-5.png";
import constructionsix from "../../assets/images/construction-img-6.png";
import constructionseven from "../../assets/images/construction-img-7.png";
import constructioneight from "../../assets/images/construction-img-8.png";
import constructionnine from "../../assets/images/construction-img-9.png";
import constructionten from "../../assets/images/construction-img-10.png";
import constructioneleven from "../../assets/images/construction-img-11.png";
import constructiontwelve from "../../assets/images/construction-img-12.png";

export const UbuconData = [
  { image: constructionOne },
  { image: constructionTwo },
  { image: constructionthree },
  { image: constructionfour },
];

export const UbuconDataTwo = [
  { image: constructionfive },
  { image: constructionsix },
  { image: constructionseven },
  { image: constructioneight },
];
export const UbuconDataThree = [
  { image: constructionnine },
  { image: constructionten },
  { image: constructioneleven },
  { image: constructiontwelve },
];
