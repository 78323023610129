import React from "react";
import { ReactComponent as UploadIcon } from "../../../../assets/icons/upload.svg";
interface IDocumentSection {
  name: string;
  id: string;
  accept: string;
  handleChange: (event: any) => void;
  uploadFiles: UploadFiles;
}

interface UploadFiles {
  logo?: File;
  bannerImage?: File;
  registrationDocument?: File;
  beeCertificate?: File;
  taxClearenceCertificate?: File;
  goodStand?: File;
  vatCertificate?: File;
  coId?: File;
}

const UploadDocuments: React.FC<IDocumentSection> = ({
  name,
  id,
  handleChange,
  accept,
}) => {
  return (
    <div>
      <label htmlFor={id} className="custom-file-upload">
        <UploadIcon />
        Upload
        <input
          id={id}
          name={name}
          type="file"
          onChange={handleChange}
          accept={accept}
          style={{
            border: "none",
            outline: "none",
          }}
        />
      </label>
    </div>
  );
};

export default UploadDocuments;
