import * as Yup from "yup";

export const CompanyFormvalidationSchema = Yup.object().shape({
  // Error msg
  CompanyName: Yup.string()
    .max(50, "companyname  must be at most 50 characters")
    .required("companyname  is required"),
  RegistrationNumber: Yup.string()
    // .matches(/^\d{12}$/, "invalid registrationnumber")
    .required("registrationnumber is required"),
  VatNumber: Yup.string().matches(/^\d{10}$/, "invalid vatnumber"),
  // .required("vatnumber is required"),
  CompanyAddress: Yup.string()
    .max(50, "companyaddress name must be at most 50 characters")
    .required("companyaddress name is required"),
  FundNumber: Yup.string()
    .matches(/^\d{12}$/, "Invalid fund number")
    .required("fund number is required"),
  Province: Yup.string()
    // .max(50, "Province name must be at most 50 characters")
    .required("Province name is required"),
  Municipalty: Yup.string()
    // .max(50, "Municipalty name must be at most 50 characters")
    .required("Municipalty name is required"),
  Name: Yup.string()
    .max(50, "Name must be at most 50 characters")
    .required("Name is required"),
  Surname: Yup.string()
    .max(50, "Surname must be at most 50 characters")
    .required("Surname is required"),
  // ContactNumber: Yup.string()
  //   .matches(/^\d{9}$/, "invalid contact number")
  //   .required("contact number is required"),
  Email: Yup.string()
    .max(50, "Email name must be at most 50 characters")
    .required("E-mail is required"),
  CeoName: Yup.string()
    .max(50, "Supervisor name must be at most 50 characters")
    .required(" Ceoname is required"),
  CeoSurname: Yup.string()
    .max(50, "CeoSurname must be at most 50 characters")
    .required("CeoSurname is required"),
  IdNumber: Yup.string()
    .matches(/^\d{13}$/, "Invalid id number")
    .required("ID number is required"),
  Directors: Yup.array().of(
    Yup.object().shape({
      DirectorsName: Yup.string()
        .max(50, "Supervisor name must be at most 50 characters")
        .required("Supervisor name is required"),
      DirectorsNumber: Yup.string()
        .matches(/^\d{13}$/, "Invalid id number")
        .required("id number is required"),
    })
  ),

  // CompanyBanner: Yup.string().required("upload valid file"),
  // CompanyLogo: Yup.string().required("upload valid file"),
  // CompanyContent: Yup.string().required("upload valid file"),
  // FileUpload: Yup.string().required("upload valid file"),
});

export const DocumentValidationScema = Yup.object().shape({
  projectName: Yup.string()
    .max(50, "Project name must be at most 50 characters")
    .required("Project name is required"),
  ceoName: Yup.string()
    .max(50, "CEO name must be at most 50 characters")
    .required("CEO name is required"),
  clientName: Yup.string()
    .max(50, "Client name must be at most 50 characters")
    .required("Client name is required"),
  commencementdate: Yup.date().required("Commencement date is required"),
  completiondate: Yup.date().required("Completion date is required"),
  // developerName: Yup.string().max(
  //   50,
  //   "DeveloperName must be at most 50 characters"
  // ),
  // // .required("DeveloperName is required"),
  // developerNumber: Yup.string().matches(/^\d{10}$/, "Invalid ID number"),
  // // .required("Mobile number is required"),
  // directorName: Yup.string()
  //   .max(50, "Director name must be at most 50 characters")
  //   .required("Director name is required"),
  // directorNumber: Yup.string()
  //   .matches(/^\d{10}$/, "Invalid Id number")
  //   .required("ID number is required"),
  // managerName: Yup.string()
  //   .max(50, "Manager name must be at most 50 characters")
  //   .required("Manager name is required"),
  // managerNumber: Yup.string()
  //   .matches(/^\d{10}$/, "Invalid Id number")
  //   .required("ID number is required"),
  // assistantManagerName: Yup.string()
  //   .max(50, "Assistant Manager name must be at most 50 characters")
  //   .required("Assistant Manager name is required"),
  // assistantManagerNumber: Yup.string()
  //   .matches(/^\d{10}$/, "Invalid Id number")
  //   .required("ID number is required"),
  // complianceOfficerName: Yup.string()
  //   .max(50, "Compliance Officer name must be at most 50 characters")
  //   .required("Compliance Officer name is required"),
  // complianceOfficerNumber: Yup.string()
  //   .matches(/^\d{10}$/, "Invalid Id number")
  //   .required("ID number is required"),
  // supervisorName: Yup.string()
  //   .max(50, "Supervisor name must be at most 50 characters")
  //   .required("Supervisor name is required"),
  // supervisorNumber: Yup.string()
  //   .matches(/^\d{10}$/, "Invalid Id number")
  //   .required("ID number is required"),
  operators: Yup.array().of(
    Yup.object().shape({
      operatorName: Yup.string()
        .max(50, "Supervisor name must be at most 50 characters")
        .required("Supervisor name is required"),
      operatorNumber: Yup.string()
        .matches(/^\d{10}$/, "Invalid Id number")
        .required("ID number is required"),
    })
  ),
  officers: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().max(50, " name must be at most 50 characters"),

      phoneNumber: Yup.string().matches(/^\d{10}$/, "Invalid Id number"),
    })
  ),
  appointment: Yup.array().of(
    Yup.object().shape({
      appointmentName: Yup.string().max(
        50,
        "Supervisor name must be at most 50 characters"
      ),
      // .required("Supervisor name is required"),
      appointmentNumber: Yup.string().matches(/^\d{10}$/, "Invalid ID number"),
      // .required("ID number is required"),
    })
  ),
});
