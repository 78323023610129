import React from "react";
import "./NoRecentFile.scss";
import { ReactComponent as NoRecentFileImg } from "../../assets/icons/norecentfile-svg.svg";

const NoRecentFile: React.FC = () => {
  return (
    <div className="no-recentfile-wrapper">
      <div>
        <h4>No recent files.</h4>
      </div>
      <div className="no-recentfile-img">
        <NoRecentFileImg />
      </div>
    </div>
  );
};

export default NoRecentFile;
