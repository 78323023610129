import React from "react";
import "./Checkbox.scss";

interface ICheckbox {
  permission: string;
  handlePermissionSelect: (permission: string) => void;
  selectedPermissions: string[];
}
const Checkbox: React.FC<ICheckbox> = ({
  permission,
  handlePermissionSelect,
  selectedPermissions,
}) => {
  return (
    <div
      className="files-add-content"
      onClick={() => handlePermissionSelect(permission)}
    >
      <input
        type="checkbox"
        id="myCheckbox"
        onChange={() => handlePermissionSelect(permission)}
        checked={selectedPermissions.includes(permission)}
      />
      <p>{permission}</p>
    </div>
  );
};

export default Checkbox;
