import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
//import Button from "../../components/Button";
import { ReactComponent as LinkedIn } from "../../assets/icons/linkedin.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";

import { ReactComponent as Instagram } from "../../assets/icons/instagram.svg";
//import { ReactComponent as Send } from "../../assets/icons/send.svg";
import Phone from "../../assets/icons/phone.png";
import Mail from "../../assets/icons/mail.png";
import Logo from "../../assets/logo/logo.png";
import NewsletterForm from "../../components/Newsletter";

const Footer: React.FC = () => {
  return (
    <div className="mx">
      <div className="footer-wrapper">
        <div className="footer-first-content">
          <Link to="/">
            <div className="logo">
              <img src={Logo} alt="" />
            </div>
          </Link>

          <div className="social-media">
            <a href="https://www.linkedin.com/company/ubucon/" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
              <LinkedIn />
            </a>
            <a href="https://www.facebook.com/ubucon" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
              <Facebook />
            </a>
            <a href="https://www.instagram.com/ubucon_services/" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>
              <Instagram />
            </a>
          </div>
        </div>
        <div className="footer-second-content">
          <div className="contact-content">
            <p>Contact</p>
            <div className="contact">
              <img src={Mail} alt="" />
              <div>
                <a href="mailto:support@ubucon.co.za">
                  <p>support@ubucon.co.za</p>
                  <p>info@ubucon.co.za</p>
                </a>
              </div>
            </div>
            <div className="contact">
              <img src={Phone} alt="" />
              <a href="tel:+734154031">
                <p>(+27) 73 415 4031</p>
                <p>(+27) 12 944 8091</p>
              </a>
            </div>
          </div>

          <NewsletterForm />
        </div>
      </div>
    </div>
  );
};

export default Footer;
