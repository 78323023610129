import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { query } from "firebase/firestore/lite";
import "./PDF.scss";
import "./Document.scss";
import Layout from "../../../components/Layout";
import ModalDownload from "../../../components/ModalDownload";
import Button from "../../../components/Button";
import { IBuynow } from "./Document";
import MoblieHeader from "../../../components/MobileHeader";
import NoRecentFile from "../../../components/NoRecentFile";
import { collection, getDocs, where } from "firebase/firestore/lite";
import { db } from "../../../utils/firebase";
import Loading from "../../../components/Loading";
import { ICompany, IDocument } from "../../../utils/types";
import { ReactComponent as Svg } from "../../../assets/icons/download.svg";
import { ReactComponent as SafetyFile } from "../../../assets/images/safetyfile-svg.svg";
import { ReactComponent as RightArrow } from "../../../assets/icons/right-arrow.svg";
import { ReactComponent as Pdf } from "../../../assets/icons/pdf.svg";

const DocumentPage: React.FC<ICompany> = ({
  id: companyId,
  CompanyName,
  Email,
  ContactNumber,
  logo,
  RegistrationNumber,
}) => {
  const { company_name } = useParams();
  const [isloading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState<IDocument[]>([]);

  const handleGetData = useCallback(async () => {
    if (!companyId) return;

    try {
      setIsLoading(true);
      const q = query(
        collection(db, "Documents"),
        where("companyId", "==", companyId)
      );
      const querySnapshot = await getDocs(q);
      const fetchedData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<IDocument, "id">),
      }));

      if (fetchedData.length) {
        setDocuments(fetchedData);
      }
      console.log(fetchedData);
    } catch (error) {
      console.error("Error fetching Firestore data:", error);
    } finally {
      setIsLoading(false); // Reset loading state to false
    }
  }, [companyId, setDocuments, setIsLoading]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  return (
    <Layout>
      <MoblieHeader />
      <div className="document-head">
        <Link to={"/company"}>
          <p>Company</p>
        </Link>
        <RightArrow />
        <Link
          to={`/company/${encodeURIComponent(company_name as string)}`}
          className="card"
        >
          <p>{decodeURIComponent(company_name as string)}</p>
        </Link>
        <RightArrow />
        <Link
          to={`/company/${encodeURIComponent(
            company_name as string
          )}/safety-files`}
        >
          <p>Documents</p>
        </Link>
      </div>

      <div className="buy-now-wrapper">
        <p>New</p>
        <div className="buy-now-content">
          {IBuynow.map((f, index) => {
            return (
              <div key={index} className="card">
                <div className="buy-now-content-file flex-content">
                  <SafetyFile />
                </div>
                <div className="buy-now-end-content">
                  <div className="pdf-file">
                    <div className="content">
                      <h6>{f.title}</h6>
                      <p>{f.date}</p>
                    </div>
                  </div>
                  <div className="new">
                    <div>
                      <h4>
                        <span>{f.dollar}</span>
                      </h4>
                    </div>
                    <div>
                      <h4>{f.rate}</h4>
                    </div>
                  </div>
                </div>
                <Link to={`/company/${company_name}/safety-files/buy`}>
                  <div className="buy-now-btn">
                    <Button varient="primary">Buy now</Button>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      {isloading ? (
        <Loading />
      ) : (
        <div className="buy-now-wrapper">
          <p>Recent</p>
          <div>
            {documents.length === 0 ? (
              <NoRecentFile />
            ) : (
              <div className="buy-now-content">
                {documents.map((f, i) => {
                  return (
                    <DocumentCard
                      key={i}
                      CompanyName={CompanyName}
                      commencementdate={f.commencementdate}
                      pdf={f.pdfs}
                      projectName={f.projectName}
                      contactNumber={ContactNumber}
                      email={Email}
                      logo={logo}
                      registerNumber={RegistrationNumber}
                      fallProtectionDeveloper={f.developer}
                      constructionDirector={f.director}
                      constructionManager={f.manager}
                      assistantConstructionManager={f.Assistantmanager}
                      complianceOfficer={f.compilance}
                      preventionSupervisor={f.Supervisor}
                      appointment={f.appointment}
                      operators={f.operators}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
};

export default DocumentPage;

interface IDocumentCard {
  projectName: string;
  commencementdate: string;
  pdf: any;
  CompanyName: string;
  email: string;
  contactNumber: number;
  logo: string;
  registerNumber: number;
  fallProtectionDeveloper: string;
  constructionDirector: string;
  constructionManager: string;
  assistantConstructionManager: string;
  complianceOfficer: string;
  preventionSupervisor: string;
  appointment: {
    appointment: string;
    appointmentNumber: number;
    appointmentName: string;
  }[];
  operators: {
    ConstructionVehicle: string;
    operatorNumber: number;
    operatorName: string;
  }[];
}

const DocumentCard: React.FC<IDocumentCard> = ({
  CompanyName,
  pdf,
  commencementdate,
  projectName,
  contactNumber,
  email,
  logo,
  registerNumber,
  fallProtectionDeveloper,
  constructionDirector,
  constructionManager,
  assistantConstructionManager,
  complianceOfficer,
  preventionSupervisor,
  // appointment,
  // operators,
}) => {
  const [isloading, setIsLoading] = useState(false);

  const downlaodPDF = async () => {
    try {
      setIsLoading(true);
      const setpdfs = [
        fallProtectionDeveloper,
        constructionDirector,
        constructionManager,
        assistantConstructionManager,
        complianceOfficer,
        preventionSupervisor,
      ];

      console.log(setpdfs);

      const response = await axios.post(
        "https://ubucon-backend.onrender.com/pdf",
        {
          pdfFiles: pdf,
          companyName: CompanyName,
          companyEmail: email,
          companyNumber: contactNumber.toString(),
          logo: logo,
          registerNumber: registerNumber.toString(),
          existingPDFs: setpdfs.filter((f) => f),
        },
        {
          responseType: "arraybuffer", // This sets the response type to binary data (PDF in this case)
        }
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Create a URL for the Blob to be used by a PDF viewer or download link
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Set the PDF data to the state

      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", `${projectName}.pdf`); // Set the desired file name here
      document.body.appendChild(link);
      link.click();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // Reset loading state to false
    }
  };

  if (isloading) {
    return <ModalDownload />;
  }

  return (
    <>
      <div className="card">
        <div className="empty-bg-clr flex-content">
          <Pdf />
        </div>
        <div className="buy-now-end-content" onClick={downlaodPDF}>
          <div className="pdf-file">
            <div className="content" style={{ cursor: "pointer" }}>
              <h6>{projectName}</h6>
              <p>{commencementdate}</p>
            </div>
          </div>
          <div className="download-icon">
            <Svg />
          </div>
        </div>
      </div>
    </>
  );
};
