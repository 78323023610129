import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaymentFaildImg from "../../assets/icons/failed.svg";

const PaymentCancel: React.FC = () => {
  const [showComponent, setShowComponent] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const replace_url = searchParams.get("replace_url");

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(false);
      if (replace_url) {
        window.location.replace(replace_url);
      }
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, [navigate, replace_url]);

  return (
    <div className="payment-content">
      {showComponent && (
        <>
          <img src={PaymentFaildImg} alt="" />
          <h2>PDF Purchased Cancel</h2>
        </>
      )}
    </div>
  );
};

export default PaymentCancel;
