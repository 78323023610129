import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import Hero from "../Hero";
import OfficeAssistance from "../OfficeAssistance";
import ComingSoon from "../ComingSoon";
import PricingPlans from "../PricingPlans";
import CreatingDataBase from "../CreatingDataBase";
import Registration from "../Registration";
import UbuconSection from "../UbuConSection";
import SplashScreen from "../../components/SplashScreen";
import Payments from "../Payments";

const Home: React.FC = () => {
  return (
    <>
      <SplashScreen />
      <Header />
      <Hero />
      <UbuconSection />
      <CreatingDataBase />
      <PricingPlans />
      <Registration />
      <ComingSoon />
      <OfficeAssistance />
      <Payments />
      <Footer />
    </>
  );
};

export default Home;
