import SafetyFile from "../../../assets/images/safety-file-img.png";
import QualityImg from "../../../assets/images/quality-img.png";
import Invoice from "../../../assets/images/invoice-img.png";

export const IFiles = [
  {
    image: SafetyFile,
    title: "Safety file",
    item: "2 Items",
    new: "1 new",
    get path() {
      return this.title.toLowerCase().split(" ").join("-");
    },
  },
  {
    image: QualityImg,
    title: "Quality assurance",
    item: "1 items",
    new: "/coming-soon",
    get path() {
      return this.new.toLowerCase().split(" ").join("-");
    },
  },
  {
    image: Invoice,
    title: "Invoice",
    item: "3 items",
    new: "/coming-soon",
    get path() {
      return this.new.toLowerCase().split(" ").join("-");
    },
  },
];
