import { Link } from "react-router-dom";
import autoAnimate from "@formkit/auto-animate";
import React, { useEffect, useRef, useState } from "react";
import "./Sidebar.scss";
import { useDataStore } from "../../store/dataStore";
import PrintingImg from "../../assets/images/printing-img.png";
import { ReactComponent as Building } from "../../assets/icons/building.svg";
import { ReactComponent as TopChevronDown } from "../../assets/icons/top-chevron-down.svg";

const Sidebar: React.FC = () => {
  const [isActive, setIsActive] = useState(false);
  const data = useDataStore((state) => state.data);

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  //auto animate
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <div className="sidebar-wrapper">
      <div className="drop-down">
        <div
          className={`sidebar-flex-container flex-content ${
            isActive ? "active" : ""
          }`}
          onClick={handleToggle}
        >
          <div className="building">
            <Building />
            <Link to="/company">
              <p>Company</p>
            </Link>
          </div>

          <TopChevronDown
            className={`drop-down-icon ${isActive ? "rotate" : ""}`}
          />
        </div>
      </div>
      <div className="Printing-Img">
        <img src={PrintingImg} alt="Printing" />
      </div>
      <div ref={parent}>
        {isActive && (
          <div className="company-name">
            <div>
              {data.map((f, index) => {
                return (
                  <div key={index} className="link-tag">
                    <Link to={`/company/${encodeURIComponent(f.CompanyName)}`}>
                      {f.CompanyName}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
