import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./CompanyFiles.scss";
import { ICompany, IDocument } from "../../utils/types";
import { collection, getDocs, query, where } from "firebase/firestore/lite";
import { db } from "../../utils/firebase";
import Layout from "../../components/Layout";
import { IFiles } from "../../data/files";
import Button from "../../components/Button";
import MoblieHeader from "../../components/MobileHeader";
import { ReactComponent as QualityImg } from "../../assets/images/qualitysvg.svg";
import { ReactComponent as Invoice } from "../../assets/images/invoice-svg.svg";
import { ReactComponent as SafetyFile } from "../../assets/images/safetyfile-svg.svg";

import Fallback from "../../assets/icons/pdf.svg";

interface IDoumentData extends ICompany {}
const CompanyFiles: React.FC<IDoumentData> = ({
  bannerImage,
  logo,
  CompanyName,
  id: companyId,
}) => {
  const [document, setDocuments] = useState<IDocument[]>([]);

  const handleGetData = useCallback(async () => {
    if (!companyId) return;

    try {
      const q = query(
        collection(db, "Documents"),
        where("companyId", "==", companyId)
      );
      const querySnapshot = await getDocs(q);
      const fetchedData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<IDocument, "id">),
      }));

      if (fetchedData.length) {
        setDocuments(fetchedData);
      }
    } catch (error) {
      console.error("Error fetching Firestore data:", error);
    } finally {
    }
  }, [companyId, setDocuments]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  const addImageFallback = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = Fallback;
    imgElement.alt = "Fallback-Image";
  };
  return (
    <>
      <Layout>
        <MoblieHeader />

        <div className="walmark-img">
          <img src={bannerImage} alt="bannerImage" onError={addImageFallback} />
        </div>
        <div className="walmark-pvt-ltd-content">
          <div className="walmark-para">
            <img src={logo} alt="logo" onError={addImageFallback} />
            <div>
              <h6>{CompanyName}</h6>
              <p>Walmart Pvt Ltd</p>
            </div>
          </div>
          <div>
            <Link to={`/company/${encodeURIComponent(CompanyName)}/edit`}>
              <Button varient="primary">View details</Button>
            </Link>
          </div>
        </div>
        <div className="files-card">
          {IFiles.map((f, index) => {
            return (
              <Link
                to={`/company/${encodeURIComponent(CompanyName)}/safety-files`}
                key={index}
              >
                <div className="card">
                  <div className="files-img flex-content">
                    <SafetyFile />
                  </div>
                  <div className="files-end-content">
                    <div className="pdf-file">
                      <img src={Fallback} alt="" onError={addImageFallback} />

                      <div className="content">
                        <h6>{f.title}</h6>
                        <p>{document.length} Items</p>
                      </div>
                    </div>
                    {/* <div className="new">
                      <p>{f.new}</p>
                    </div> */}
                  </div>
                </div>
              </Link>
            );
          })}
          <div className="card">
            <div className="files-img flex-content">
              <QualityImg />
            </div>
            <div className="files-end-content">
              <div className="pdf-file">
                <img src={Fallback} alt="" />

                <div className="content">
                  <h6>Quality assurance</h6>
                  {/* <p>1 items</p> */}
                </div>
              </div>
              <div className="new">
                <p>/coming-soon</p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="files-img flex-content">
              <Invoice />
            </div>
            <div className="files-end-content">
              <div className="pdf-file">
                <img src={Fallback} alt="" />
                <div className="content">
                  <h6>Invoice</h6>
                  {/* <p>3 items</p> */}
                </div>
              </div>
              <div className="new">
                <p>/coming-soon</p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CompanyFiles;
