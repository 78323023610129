export interface ICidb {
  content: string;
}

export const Cidb: ICidb[] = [
  {
    content: "GB - General Building",
  },
  {
    content: "CE - Civil Engineering",
  },

  {
    content: "EB - Electrical Engineering Works - Building",
  },
  {
    content: "EP - Electrical Engineering Works - Infrastructure",
  },
  {
    content: "ME - Mechanical Engineering",
  },
  {
    content: "SB - Asphalt Works ( Supply and Lay )",
  },
  {
    content: "SC - Building Excavations, Shaft Sinking, Lateral Earth Support",
  },
  {
    content: "SD - Corrosion Protection ( Cathodic, Anodic, Electronic )",
  },
  {
    content: "SE - Demolition and Blasting",
  },
  {
    content: "SF - Fire Prevention and Protection Systems",
  },
  {
    content: "SG - Glazing, Curtain Walls, Shop Fronts",
  },
  {
    content: "SH - Landscaping, Irrigation and Horticulture",
  },
  {
    content:
      "SI - Lifts, Escalators and Travellators ( Installation, Commissioning and Maintenance )",
  },
  {
    content: "SJ - Piling and Specialized Foundations for Building Structures",
  },
  {
    content: "SK - Road Marking and Signage",
  },
  {
    content: "SL - Structural Steelwork Fabrication and Erection",
  },
  {
    content: "SM - Timber Buildings and Structures",
  },
  {
    content:
      "SN - Waterproofing of Basements, Roofs and Walls using specialist Systems",
  },
  {
    content:
      "SO - Water Supply and Drainage for Buildings ( Wet Services, Plumbing )",
  },
  {
    content: "SQ - Steel Security Fencing or Precast Concrete",
  },
];
