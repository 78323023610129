import { signOut } from "firebase/auth";
import autoAnimate from "@formkit/auto-animate";
import { Link, useNavigate } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore/lite";
import React, { useCallback, useEffect, useRef, useState } from "react";

import "./Header.scss";
import Button from "../Button";
import { auth, db } from "../../utils/firebase";
import { userStore } from "../../store/userStore";

import Logo from "../../assets/logo/logo.png";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as Bell } from "../../assets/icons/bx-bell.svg.svg";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const parent = useRef(null);
  const [logoutToggle, setLogoutToggle] = useState(false);
  const user = userStore((state) => state.user);
  const updateUserData = userStore((state) => state.updateUserData);

  const handleGetUserData = useCallback(async () => {
    if (!user) return;

    try {
      const userData = query(
        collection(db, "Users"),
        where("email", "==", user.email)
      );
      const data = await getDocs(userData);
      const fetchedData = data.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as any),
      }));

      fetchedData.forEach((f) => {
        updateUserData({ email: f.email, isNewUser: f.isNewUser });
      });
    } catch (error) {
      console.log(error);
    }
  }, [user, updateUserData]);

  useEffect(() => {
    handleGetUserData();
  }, [handleGetUserData]);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const handleLogoutToggle = () => {
    setLogoutToggle(!logoutToggle);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      const user = auth.currentUser;
      if (!user) {
        navigate("/login");
        console.log("Signed out successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="header-wrapper">
      <div className="mx">
        <div className="header-container">
          <div className="logo-img">
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
          </div>
          <div className="profiles-content flex-content">
            <div className="bell">
              <Bell />
            </div>

            <div onClick={handleLogoutToggle}>
              <div className="profile">
                <p>{user?.displayName?.slice(0, 1)}</p>
              </div>
            </div>
          </div>
        </div>
        <div ref={parent}>
          {logoutToggle && (
            <div className="logout-content">
              <div className="profile">
                <p>{user?.displayName?.slice(0, 1)}</p>
              </div>
              <h6>{user?.displayName}</h6>
              <p>{user?.email}</p>
              <Button
                varient="primary"
                leftIcon={<Logout />}
                onClick={handleLogout}
              >
                Log out
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
