import React from "react";
import { Link } from "react-router-dom";
import "./AddCompanyContinue.scss";
import Button from "../Button";
import { ReactComponent as AddCompanyImg } from "../../assets/icons/addcompany-svg.svg";
import Plus from "../../assets/icons/plus.png";

const AddCompanyContinue: React.FC = () => {
  return (
    <div className="add-company-wrapper">
      <div>
        <h4>
          Add company to <br /> continue.
        </h4>
        <div className="add-company-btn">
          <Link to="/company-details">
            <Button varient="primary" leftIcon={<img src={Plus} alt="plus" />}>
              Add company
            </Button>
          </Link>
        </div>
      </div>
      <div className="add-company-img">
        <AddCompanyImg />
      </div>
    </div>
  );
};

export default AddCompanyContinue;
