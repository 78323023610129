export const DemoPricingPlans = [
  {
    title: "First Purchase Special",
    tittle2: "",
    price: "R  999. 00",
  },
  {
    title: "Thereafter for each",
    tittle2: "File",

    price: "R  1699. 00",
  },
  {
    title: "First Purchase Special",
    tittle2: "",
    price: "R  999. 00",
  },
];
