import React from "react";
import "./payments.scss";
import { ReactComponent as Logoone } from "../../assets/icons/moretyme-payment-1.svg";
import { ReactComponent as Logotwo } from "../../assets/icons/mastercard-payment-2.svg";
import { ReactComponent as Logothree } from "../../assets/icons/zapper-payment-3.svg";
import { ReactComponent as Logofour } from "../../assets/icons/snapscan-payment-4.svg";
import { ReactComponent as Logofive } from "../../assets/icons/mobicred-payment-5.svg";
import { ReactComponent as Logosix } from "../../assets/icons/code-payment-6.svg";
import { ReactComponent as Logoseven } from "../../assets/icons/instant-payment-7.svg";

const Payments = () => {
  return (
    <div className="mx">
      <div className="payments">
        <h4>Supported payment</h4>
        <div className="payment-section">
          <div className="payment-logo">
            <Logoone />
            <Logotwo />
            <Logothree />
            <Logofour />
            <Logofive />
          </div>
          <div className="payment-logo">
            <Logosix />
            <Logoseven />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payments;
