import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import autoAnimate from "@formkit/auto-animate";
import "./ForgotPassword.scss";
import AuthLayoutPage from "../../layouts/AuthLayoutPage";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { useUserStore } from "../../store/store";
import Logo from "../../assets/logo/ubu-con-logo-2.png";

const ForgotPassword: React.FC = () => {
  const user = useUserStore((state) => state.user);
  console.log("user", user);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const parent = useRef(null);

  //auto animate

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleSubmit = async (values: any) => {
    const { email } = values;
    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
      console.log("Password reset email sent");
      setErrorMessage("Password reset email sent");
    } catch (error) {
      console.error("Error sending password reset email", error);
      setErrorMessage("Failed to send password reset email. Please try again.");
    }
  };

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <AuthLayoutPage>
            <div className="forgot-container">
              <div ref={parent}>
                {errorMessage && (
                  <div className="error-window">
                    <p>{errorMessage}</p>
                  </div>
                )}
              </div>
              <div className="logo">
                <img src={Logo} alt="logo" />
              </div>
              <div className="forgot-wrapper flex-content">
                <div>
                  <div className="forgot-text">Forgot password</div>
                  <p>
                    Please enter your email address you will receive a link to
                    create a new password via email.
                  </p>
                  <Input
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    label="E-mail"
                  />

                  <Button
                    varient="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Send link
                  </Button>

                  <div className="sign-up-content">
                    <Link to="/signup">Back to Sign in</Link>
                  </div>
                </div>
              </div>
            </div>
          </AuthLayoutPage>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
