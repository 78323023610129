import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaymentSuccessImg from "../../assets/icons/successful-img.svg";

const PaymentSuccess: React.FC = () => {
  const [showComponent, setShowComponent] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const replace_url = searchParams.get("replace_url");

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowComponent(false);

      if (replace_url) {
        window.location.replace(replace_url);
      }
    }, 6000);

    return () => {
      clearTimeout(timer);
    };
  }, [navigate, replace_url]);

  return (
    <div className="payment-content">
      {showComponent && (
        <div>
          <img src={PaymentSuccessImg} alt="" />
          <h2>PDF Purchased Successfully</h2>
        </div>
      )}
    </div>
  );
};

export default PaymentSuccess;
