import "../../../../components/Checkbox/Checkbox.scss";
import { ICidb } from "../../data/Cidb";
import { IData } from "../..";
import Button from "../../../../components/Button";
import { ReactComponent as UploadIcon } from "../../../../assets/icons/upload.svg";
import { ReactComponent as Delete } from "../../../../assets/icons/delete.svg";

interface ICheckbox extends ICidb {
  setSelecteDCidb: React.Dispatch<React.SetStateAction<IData[]>>;
  selectedCidb: IData[];
  index: number;
}
const Select: React.FC<ICheckbox> = ({
  selectedCidb,
  setSelecteDCidb,
  content,
}) => {
  const data = selectedCidb.find((f) => f.content === content);
  const maxSize = 2 * 1024 * 1024; // 2MB (example limit)

  return (
    <div>
      <div className="cidb">
        <div className="cidb-checked">
          <input
            type="checkbox"
            id="myCheckbox"
            onChange={(e) => {
              if (e.target.checked) {
                const findData = selectedCidb.find(
                  (f) => f.content === content
                );

                if (!findData) {
                  setSelecteDCidb((d) => [
                    ...d,
                    {
                      content,
                      pdf: undefined,
                      level: 1,
                      checked: true,
                    },
                  ]);
                  return;
                }
              } else {
                setSelecteDCidb((d) => [
                  ...d.filter((f) => f.content !== content),
                ]);
              }
            }}
          />
          <p>{content}</p>
        </div>

        <div className="cidb-upload">
          <div className="circle">
            {Array.from({ length: 9 }).map((_, i) => (
              <div
                key={i.toString()}
                className="circleleavel"
                style={{
                  background: i + 1 === data?.level ? "black" : "",
                  color: i + 1 === data?.level ? "#fff" : "",
                }}
                onClick={() => {
                  const clonedArray = selectedCidb;

                  const index = selectedCidb.findIndex(
                    (f) => f.content === content
                  );

                  clonedArray[index].level = i + 1;

                  setSelecteDCidb([...clonedArray]);
                }}
              >
                {i + 1}
              </div>
            ))}
          </div>
          {!data?.pdf ? (
            <div>
              <label className="custom-file-upload">
                <UploadIcon />
                Upload
                <input
                  type="file"
                  accept="application/pdf"
                  onChange={(e: any) => {
                    const files = e.target.files[0];
                    if (files.size > maxSize) {
                      alert(
                        "File size exceeds the limit. Please choose a smaller file."
                      );
                      return;
                    }

                    const clonedArray = [...selectedCidb];

                    const findIndex = selectedCidb.findIndex((f) => {
                      console.log(f.content);
                      console.log(content);
                      return f.content === content;
                    });

                    console.log(findIndex);
                    console.log(clonedArray);
                    clonedArray[findIndex].pdf = files;
                    setSelecteDCidb([...clonedArray]);
                  }}
                  style={{
                    border: "none",
                    outline: "none",
                  }}
                />
              </label>
            </div>
          ) : (
            <Button
              varient="secondary"
              onClick={() => {
                const clonedArray = selectedCidb;

                const index = selectedCidb.findIndex(
                  (f) => f.content === content
                );
                clonedArray[index].pdf = undefined;

                setSelecteDCidb([...clonedArray]);
              }}
            >
              delete
              <Delete />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Select;
