import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import "./OfficeAssistance.scss";
import BackgroundImg from "../../assets/images/background.png";
import OfficeAssistanceImg from "../../assets/images/officeAssistance-img.png";

const OfficeAssistance: React.FC = () => {
  const [isModalOpen, setModalState] = useState(false);
  const toggleModal = () => setModalState(!isModalOpen);
  return (
    <div className="mx">
      <div className="office-assistance-wrapper">
        <div className="bg-img-content">
          <img src={BackgroundImg} alt="" />
          <div className="content">
            <h4>
              For an affordable monthly subscription fee, you can have all your
              admin in one place!
            </h4>
          </div>
        </div>
        <div className="officeAssistanceImg">
          <img src={OfficeAssistanceImg} alt="" />
        </div>
      </div>
      <div className="heading">
        <h4>Be your own office assistant</h4>
        <div className="flex-content">
          <Link to="/login">
            <Button varient="primary" onClick={toggleModal}>
              Register now
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OfficeAssistance;
