import React, { useEffect, useRef, useState } from "react";
import Header from "../../components/Header";
import "./StepLayout.scss";
import printimg from "../../assets/images/printing-img.png";
import line from "../../assets/icons/line-svg.svg";

interface ILayoutProps {
  children: React.ReactNode;
}

const StepLayout: React.FC<ILayoutProps> = ({ children }) => {
  const [isActive, setisActive] = useState<string | null>("step1");
  const [activeColor, setActiveColor] = useState(false);
  const [, setActive] = useState(0);
  const targetref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.target && entry.isIntersecting) {
            setisActive(entry.target.getAttribute("id"));
          }
        });
      },
      { threshold: 0.5 }
    );

    const sections = document.querySelectorAll("aside");

    sections.forEach((section) => observer.observe(section));
  }, []);

  return (
    <>
      <div className="header">
        <Header />
      </div>
      <div className="layoutWrapper">
        <div className="sidebar-wrapper">
          <div>
            <a href="#step1">
              <div
                className="step-section"
                onClick={() => setisActive("step1")}
              >
                <div
                  className="step"
                  ref={targetref}
                  onClick={() => setActive(1)}
                >
                  <div
                    className="circle"
                    style={{
                      background: isActive === "step1" ? "#eec900" : "",
                    }}
                  ></div>
                  <img src={line} alt="line" width="3px" height="76px" />
                </div>

                <h2>
                  Step <span>1</span>
                </h2>
              </div>
            </a>
            <a href="#step2" onClick={() => setActiveColor(!activeColor)}>
              <div
                className="step-section"
                onClick={() => setisActive("step2")}
              >
                <div
                  className="step"
                  ref={targetref}
                  onClick={() => setActive(2)}
                >
                  <div
                    className="circle"
                    style={{
                      background: isActive === "step2" ? "#eec900" : "",
                    }}
                  ></div>
                  <img src={line} alt="line" width="3px" height="76px" />
                </div>

                <h2>
                  Step <span>2</span>
                </h2>
              </div>
            </a>
            <a href="#step3" onClick={() => setActiveColor(!activeColor)}>
              <div
                className="step-section"
                onClick={() => setisActive("step3")}
              >
                <div
                  className="step"
                  ref={targetref}
                  onClick={() => setActive(3)}
                >
                  <div
                    className="circle"
                    style={{
                      background: isActive === "step3" ? "#eec900" : "",
                    }}
                  ></div>
                  <img src={line} alt="line" width="3px" height="76px" />
                </div>
                <h2>
                  Step <span>3</span>
                </h2>
              </div>
            </a>
            <a href="#step4" onClick={() => setActiveColor(!activeColor)}>
              <div
                className="step-section"
                onClick={() => setisActive("step4")}
              >
                <div
                  className="step"
                  ref={targetref}
                  onClick={() => setActive(4)}
                >
                  <div
                    className="circle"
                    style={{
                      background: isActive === "step4" ? "#eec900" : "",
                    }}
                  ></div>
                  <img src={line} alt="line" width="3px" height="76px" />
                </div>
                <h2>
                  Step <span>4</span>
                </h2>
              </div>
            </a>
            <a href="#step5" onClick={() => setActiveColor(!activeColor)}>
              <div
                className="step-section"
                onClick={() => setisActive("step5")}
              >
                <div
                  className="step"
                  ref={targetref}
                  onClick={() => setActive(5)}
                >
                  <div
                    className="circle"
                    style={{
                      background: isActive === "step5" ? "#eec900" : "",
                    }}
                  ></div>
                </div>
                <h2>
                  Step <span>5</span>
                </h2>
              </div>
            </a>
          </div>
          <div className="printing-Img">
            <img src={printimg} alt="printimg" />
          </div>
        </div>
        <div className="layout-children">{children}</div>
      </div>
    </>
  );
};

export default StepLayout;
