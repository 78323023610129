import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Form, Formik, Field, FieldArray, ErrorMessage } from "formik";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Link } from "react-router-dom";
import { addDoc, collection } from "firebase/firestore/lite";
import { v4 } from "uuid";
import { userStore } from "../../store/userStore";
import "./CompanyDetailsForm.scss";
import "../../components/MobileHeader/MobileHeader.scss";
import Select from "./component/Select";
import { Cidb } from "./data/Cidb";
import Input from "../../components/Input";
import { headerStore } from "../../store/headerStore";
import { db, storage } from "../../utils/firebase";
import MOdalPopUp from "../../components/ModalPopupBox";
import { provinceData } from "./data/provience";
import StepLayout from "../../layouts/StepLayout";
import Button from "../../components/Button";
import { CompanyFormvalidationSchema } from "../../utils/validationSchema";
import CompanyFormHeader from "./component/CompanyFormHeader";
import UploadDocumentFiles from "./component/UploadFiles";
import UploadDocuments from "./component/UploadDocuments";
import { ReactComponent as TopChevronDown } from "../../assets/icons/top-chevron-down.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete-icon.svg";
import pdf from "../../assets/images/pdf-img.png";

const initialValues = {
  CompanyName: "",
  RegistrationNumber: "",
  VatNumber: "",
  CompanyAddress: "",
  FundNumber: "",
  Municipalty: "",
  Name: "",
  Surname: "",
  ContactNumber: "",
  Email: "",
  CeoName: "",
  CeoSurname: "",
  IdNumber: "",
  Province: "",
  Directors: [{ DirectorsName: "", DirectorsNumber: "" }],
};

interface UploadFiles {
  logo?: File;
  bannerImage?: File;
  registrationDocument?: File;
  beeCertificate?: File;
  taxClearenceCertificate?: File;
  goodStand?: File;
  vatCertificate?: File;
  coId?: File;
}

interface Director {
  file: File;
  index: number;
}

interface ICompanyFiles {
  id: string;
  isModalOpen: boolean;
  toggleModal: () => void;
}

export interface IData {
  level: number;
  content: string;
  pdf?: File;
  checked: boolean;
}

const CompanyDetailForm: React.FC<ICompanyFiles> = ({ id }) => {
  const [logo, setLogo] = useState("");
  const [banner, setBanner] = useState("");
  const [uploadFiles, setUploadFiles] = useState<UploadFiles>({});
  const user = userStore((s) => s.user);
  const navigate = useNavigate();
  const [selectedCidb, setSelectedCidb] = useState<IData[]>([]);
  const [directors, setDirectors] = useState<Director[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [openToggle, setOpenToggle] = useState(false);
  const [, setFileSizeExceeded] = useState(false);
  const [isModalOpen, setModalState] = useState(false);
  const toggleModal = () => setModalState(!isModalOpen);
  const handleOpenToggle = () => {
    setOpenToggle(!openToggle);
    setIsOpen(!isOpen);
  };

  const update = headerStore((store) => store.update);

  useEffect(() => {
    const handleScrollView = () => {
      if (window.scrollY >= 50) {
        update(true);
      } else {
        update(false);
      }
    };

    window.addEventListener("scroll", handleScrollView);
    return () => {
      window.removeEventListener("scroll", handleScrollView);
    };
  }, [update]);

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      let urls = {};
      const files = Object.entries(uploadFiles).map((d) => d);

      let uploadedDocuments = 0;

      await Promise.all(
        files.map(async (file) => {
          const imageref = ref(storage, `imagefile/${uploadFiles + v4()}`);
          const uploadTask = uploadBytesResumable(imageref, file[1]);

          await uploadTask;

          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log("Download URL:", downloadURL);
          uploadedDocuments += 1;
          console.log(uploadedDocuments);
          urls = { ...urls, [file[0]]: downloadURL };
        })
      );
      const directorUrl = await Promise.all(
        directors.map(async (f) => {
          const imageref = ref(storage, `imagefile/${v4()}`);
          const uploadTask = uploadBytesResumable(imageref, f.file as any);
          await uploadTask;

          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          uploadedDocuments += 1;
          console.log(uploadedDocuments);
          console.log("Download URL:", downloadURL);
          return { index: f.index, DirectorsFiles: downloadURL };
        })
      );
      const newDirectorsData = values.Directors.map((m, i) => {
        const data = directorUrl.find((f) => f.index === i);
        if (data) {
          return { ...m, DirectorsFiles: data.DirectorsFiles };
        }

        return {
          ...m,
        };
      });

      console.log(selectedCidb);
      console.log(Cidb);

      const CidbUrl = await Promise.all(
        selectedCidb
          .filter((f) => f.pdf)
          .map(async (f) => {
            const imageref = ref(storage, `imagefile/${v4()}`);
            const uploadTask = uploadBytesResumable(imageref, f.pdf as any);

            await uploadTask;

            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log("Download URL:", downloadURL);
            uploadedDocuments += 1;
            console.log(uploadedDocuments);
            return {
              content: f.content,
              level: f.level,
              CIDBFiles: downloadURL,
            };
          })
      );
      const docRef = await addDoc(collection(db, "companyInfo"), {
        ...values,
        userId: user?.uid,
        ...urls,
        cidb: CidbUrl,
        Directors: newDirectorsData,
      });
      console.log(docRef);

      navigate("/company");
    } catch (error) {
      alert(JSON.stringify("clear error"));
      console.error("Error adding document: ", error);
    }
    console.log("values", values);
  };

  return (
    <StepLayout>
      <CompanyFormHeader />

      <div className="CompanyDetailForm">
        <Formik
          initialValues={initialValues}
          validationSchema={CompanyFormvalidationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, setValues, errors }) => (
            <Form>
              <div>
                {/* company-details */}
                <div>
                  <aside className="company-details" id="step1">
                    <>
                      <h2>
                        Company details<span>.</span>
                      </h2>
                      <div className="logosection">
                        <h6>COMPANY LOGO</h6>
                        <div className="company-logo">
                          <div className="upload-logo">
                            <div className="display-img">
                              {uploadFiles["logo"] ? (
                                <img
                                  src={logo}
                                  alt="uploaded file"
                                  height={80}
                                  width={80}
                                />
                              ) : (
                                <img
                                  src={pdf}
                                  alt="uploaded file"
                                  height={80}
                                  width={80}
                                />
                              )}
                            </div>
                            <div>
                              <p>Upload company logo (JPG, PNG, GIF)</p>
                              <span>{uploadFiles["logo"]?.name}</span>
                            </div>
                          </div>
                          {!uploadFiles["logo"] ? (
                            <UploadDocuments
                              name={"CompanyLogo"}
                              id={"logoupload"}
                              accept={"image/jpg/,image/png"}
                              handleChange={(event: any) => {
                                const file = event.target.files[0];
                                const maxSize = 5 * 1024 * 1024; // 5MB (example limit)
                                if (file.size > maxSize) {
                                  setFileSizeExceeded(true);
                                  alert(
                                    "File size exceeds the limit. Please choose a smaller file."
                                  );
                                  return;
                                }
                                setUploadFiles((e) => ({
                                  ...e,
                                  logo: file,
                                }));
                                const fileReader = new FileReader();
                                fileReader.onload = (r) => {
                                  setLogo(r.target?.result as string);
                                };
                                fileReader.readAsDataURL(file);
                              }}
                              uploadFiles={uploadFiles}
                            />
                          ) : (
                            <Button
                              varient="secondary"
                              onClick={() =>
                                setUploadFiles((e) => ({
                                  ...e,
                                  logo: undefined,
                                }))
                              }
                            >
                              Delete
                              <Delete />
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className="bannersection">
                        <h6>COMPANY BANNER IMAGE</h6>
                        <div className="company-logo">
                          <div className="upload-logo">
                            <div className="display-img">
                              {uploadFiles["bannerImage"] ? (
                                <img
                                  src={banner} //bannerimage
                                  alt="uploaded file"
                                  height={80}
                                  width={80}
                                />
                              ) : (
                                <img
                                  src={pdf}
                                  alt="uploaded file"
                                  height={80}
                                  width={80}
                                />
                              )}
                            </div>
                            <div className="file-valaidation">
                              <p>Upload company banner image (JPG, PNG, GIF)</p>

                              <span>{uploadFiles["bannerImage"]?.name}</span>
                            </div>
                          </div>
                          <div>
                            {!uploadFiles["bannerImage"] ? (
                              <div>
                                <UploadDocuments
                                  name={"CompanyBanner"}
                                  id={"bannerupload"}
                                  handleChange={(event: any) => {
                                    const file = event.target.files[0];
                                    const maxSize = 5 * 1024 * 1024; // 5MB (example limit)
                                    if (file.size > maxSize) {
                                      setFileSizeExceeded(true);
                                      alert(
                                        "File size exceeds the limit. Please choose a smaller file."
                                      );
                                      return;
                                    }
                                    setUploadFiles((e) => ({
                                      ...e,
                                      bannerImage: file,
                                    }));
                                    const fileReader = new FileReader();
                                    fileReader.onload = (r) => {
                                      setBanner(r.target?.result as string);
                                    };
                                    fileReader.readAsDataURL(file);
                                  }}
                                  uploadFiles={uploadFiles}
                                  accept={"image/jpg,image/png"}
                                />
                              </div>
                            ) : (
                              <Button
                                varient="secondary"
                                onClick={() =>
                                  setUploadFiles((e) => ({
                                    ...e,
                                    bannerImage: undefined,
                                  }))
                                }
                              >
                                Delete
                                <Delete />
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="companydetials-wrapper">
                        <Input
                          name="CompanyName"
                          type="text"
                          placeholder="UBUcon contracting services"
                          label="Registered Company name"
                        />

                        <div className="register-detials">
                          <div className="input-wrapper">
                            <div className="label-content">
                              <label htmlFor="RegistrationNumber">
                                Company Registration Number
                              </label>
                            </div>
                            <div className="input-content">
                              <input
                                name="RegistrationNumber"
                                type="text"
                                id="number"
                                value={values.RegistrationNumber}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d{14}$/.test(value)) {
                                    return;
                                  }
                                  if (value.length === 4) {
                                    setValues((v) => ({
                                      ...v,
                                      RegistrationNumber: value + "/",
                                    }));

                                    return;
                                  }
                                  if (value.length === 11) {
                                    setValues((v) => ({
                                      ...v,
                                      RegistrationNumber: value + "/",
                                    }));
                                    return;
                                  }
                                  console.log(value.length);
                                  if (value.length === 15) {
                                    return;
                                  }

                                  setValues((v) => ({
                                    ...v,
                                    RegistrationNumber: value,
                                  }));
                                }}
                                onKeyDown={(
                                  e: React.KeyboardEvent<HTMLInputElement>
                                ) => {
                                  if (e.key === "Backspace") {
                                    setValues((v) => ({
                                      ...v,
                                      RegistrationNumber:
                                        values.RegistrationNumber.slice(0, -1),
                                    }));
                                  }
                                }}
                                placeholder="Enter company registration number"
                              />
                            </div>
                            <ErrorMessage
                              className="errorMsg"
                              component="div"
                              name="RegistrationNumber"
                            />
                          </div>
                          <div>
                            <Input
                              name="VatNumber"
                              type="number"
                              id="number"
                              placeholder="Enter VAT number"
                              label="VAT Number(If registered)"
                            />
                          </div>
                          <div>
                            <Input
                              name="FundNumber"
                              type="number"
                              id="number"
                              placeholder="Enter VAT number"
                              label="Compensation Fund Number"
                            />
                          </div>
                        </div>
                        <div className="company-address">
                          <Input
                            name="CompanyAddress"
                            type="text"
                            placeholder="Enter company address"
                            label="Company address"
                          />
                        </div>
                      </div>
                      <div className="company-select">
                        <div className="select-option">
                          <div className="company-area">
                            <label>Province</label>
                            <div className="select-value">
                              <Field as="select" name="Province">
                                <option value="">Select Province</option>
                                {provinceData.map((item) => {
                                  return (
                                    <option value={item.title}>
                                      {item.title}
                                    </option>
                                  );
                                })}
                              </Field>
                            </div>
                          </div>
                          <div className="company-area">
                            <label>Municipality</label>

                            <Field as="select" name="Municipalty">
                              <option value="">Select Municipalty</option>

                              {provinceData
                                .find((f) => f.title === values.Province)
                                ?.municipality.map((item, index) => {
                                  return (
                                    <option key={index} value={item}>
                                      {item}
                                    </option>
                                  );
                                })}
                            </Field>
                          </div>
                        </div>
                        <label>CIDB Registration</label>

                        <div className="cidb-section">
                          <div
                            className={`cidb-register  ${
                              openToggle ? "active" : ""
                            }`}
                            onClick={handleOpenToggle}
                          >
                            <p>Select</p>
                            <TopChevronDown
                              className={`drop-down-icon ${
                                openToggle ? "rotate" : ""
                              }`}
                            />
                          </div>
                          {isOpen &&
                            Cidb.map((permission, index) => {
                              return (
                                <div key={index}>
                                  <Select
                                    index={index}
                                    {...permission}
                                    setSelecteDCidb={setSelectedCidb}
                                    selectedCidb={selectedCidb}
                                  />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </>
                  </aside>
                </div>

                {/* CompanyContactDetails */}

                <div className="mx">
                  <aside className="company-contact-detials" id="step2">
                    <h2>
                      Company contact details<span>.</span>
                    </h2>
                    <div>
                      <div className="contact-detials">
                        <div>
                          <Input
                            name="Name"
                            type="text"
                            placeholder="Enter name"
                            label="NAME"
                          />
                        </div>
                        <div>
                          <Input
                            name="Surname"
                            type="text"
                            placeholder="Enter surname"
                            label="SURNAME"
                          />
                        </div>
                      </div>
                      <div className="contact-detials">
                        <div>
                          <label>
                            CONTACT NUMBER
                            <PhoneInput
                              inputClass="contact-input"
                              country={"us"}
                              onChange={(phone) => {
                                setValues((v) => ({
                                  ...v,
                                  ContactNumber: phone,
                                }));
                              }}
                              inputProps={{ required: true }}
                              placeholder="Enter contact number"
                            />
                          </label>
                          <ErrorMessage
                            className="errorMsg"
                            component="div"
                            name="ContactNumber"
                          />
                        </div>
                        <div>
                          <Input
                            name="Email"
                            type="email"
                            placeholder="Enter e-mail address"
                            label="E-MAIL"
                          />
                        </div>
                      </div>
                    </div>
                  </aside>
                </div>

                {/* UploadableDocuments */}
                <div className="mx">
                  <aside id="step3">
                    <h2>
                      Uploadable documents<span>.</span>
                    </h2>
                    <div className="documents-info">
                      <div className="pdf-logo">
                        <div className="display-img">
                          <img
                            src={pdf}
                            alt="uploaded file"
                            height={80}
                            width={80}
                          />
                        </div>
                        <div>
                          <p>Upload company registration document (pdf).</p>
                          <span>
                            {uploadFiles["registrationDocument"]?.name}
                          </span>
                        </div>
                      </div>
                      {!uploadFiles["registrationDocument"] ? (
                        <div>
                          <UploadDocuments
                            name={"registrationDocument"}
                            id={"registerfileupload"}
                            handleChange={(event: any) => {
                              const file = event.target.files[0];
                              const maxSize = 5 * 1024 * 1024; // 5MB (example limit)
                              if (file.size > maxSize) {
                                setFileSizeExceeded(true);
                                alert(
                                  "File size exceeds the limit. Please choose a smaller file."
                                );
                                return;
                              }
                              setUploadFiles((e) => ({
                                ...e,
                                registrationDocument: file,
                              }));
                            }}
                            uploadFiles={uploadFiles}
                            accept={"application/pdf"}
                          />
                        </div>
                      ) : (
                        <Button
                          varient="secondary"
                          onClick={() =>
                            setUploadFiles((e) => ({
                              ...e,
                              registrationDocument: undefined,
                            }))
                          }
                        >
                          Delete
                          <Delete />
                        </Button>
                      )}
                    </div>
                    <div className="documents-info">
                      <div className="pdf-logo">
                        <div className="display-img">
                          <img
                            src={pdf}
                            alt="uploaded file"
                            height={80}
                            width={80}
                          />
                        </div>
                        <div>
                          <p>Upload BEE certificate (pdf).</p>
                          <span>{uploadFiles["beeCertificate"]?.name}</span>
                        </div>
                      </div>
                      {!uploadFiles["beeCertificate"] ? (
                        <div>
                          <UploadDocuments
                            name={"beeCertificate"}
                            id={"beeCertificatefileupload"}
                            accept={"application/pdf"}
                            handleChange={(event: any) => {
                              const file = event.target.files[0];
                              const maxSize = 5 * 1024 * 1024; // 5MB (example limit)
                              if (file.size > maxSize) {
                                setFileSizeExceeded(true);
                                alert(
                                  "File size exceeds the limit. Please choose a smaller file."
                                );
                                return;
                              }
                              setUploadFiles((e) => ({
                                ...e,
                                beeCertificate: file,
                              }));
                            }}
                            uploadFiles={uploadFiles}
                          />
                        </div>
                      ) : (
                        <Button
                          varient="secondary"
                          onClick={() =>
                            setUploadFiles((e) => ({
                              ...e,
                              beeCertificate: undefined,
                            }))
                          }
                        >
                          Delete
                          <Delete />
                        </Button>
                      )}
                    </div>
                    <div className="documents-info">
                      <div className="pdf-logo">
                        <div className="display-img">
                          <img
                            src={pdf}
                            alt="uploaded file"
                            height={80}
                            width={80}
                          />
                        </div>
                        <div>
                          <p>Upload tax clearance certificate (pdf).</p>
                          <span>
                            {uploadFiles["taxClearenceCertificate"]?.name}
                          </span>
                        </div>
                      </div>
                      {!uploadFiles["taxClearenceCertificate"] ? (
                        <div>
                          <UploadDocuments
                            name={"taxClearenceCertificate"}
                            id={"taxfileupload"}
                            handleChange={(event: any) => {
                              const file = event.target.files[0];
                              const maxSize = 5 * 1024 * 1024; // 5MB (example limit)
                              if (file.size > maxSize) {
                                setFileSizeExceeded(true);
                                alert(
                                  "File size exceeds the limit. Please choose a smaller file."
                                );
                                return;
                              }
                              setUploadFiles((e) => ({
                                ...e,
                                taxClearenceCertificate: file,
                              }));
                            }}
                            uploadFiles={uploadFiles}
                            accept={"application/pdf"}
                          />
                        </div>
                      ) : (
                        <Button
                          varient="secondary"
                          onClick={() =>
                            setUploadFiles((e) => ({
                              ...e,
                              taxClearenceCertificate: undefined,
                            }))
                          }
                        >
                          Delete
                          <Delete />
                        </Button>
                      )}
                    </div>
                    <div className="documents-info">
                      <div className="pdf-logo">
                        <div className="display-img">
                          <img
                            src={pdf}
                            alt="uploaded file"
                            height={80}
                            width={80}
                          />
                        </div>
                        <div>
                          <p>
                            Upload letter of good standing certificate (pdf).
                            <span>{uploadFiles["goodStand"]?.name}</span>
                          </p>
                        </div>
                      </div>
                      {!uploadFiles["goodStand"] ? (
                        <div>
                          <UploadDocuments
                            name={"goodStand"}
                            id={"goodStandfileupload"}
                            handleChange={(event: any) => {
                              const file = event.target.files[0];
                              const maxSize = 5 * 1024 * 1024; // 5MB (example limit)
                              if (file.size > maxSize) {
                                setFileSizeExceeded(true);
                                alert(
                                  "File size exceeds the limit. Please choose a smaller file."
                                );
                                return;
                              }
                              setUploadFiles((e) => ({
                                ...e,
                                goodStand: file,
                              }));
                            }}
                            uploadFiles={uploadFiles}
                            accept={"application/pdf"}
                          />
                        </div>
                      ) : (
                        <Button
                          varient="secondary"
                          onClick={() =>
                            setUploadFiles((e) => ({
                              ...e,
                              goodStand: undefined,
                            }))
                          }
                        >
                          Delete
                          <Delete />
                        </Button>
                      )}
                    </div>
                    <div className="documents-info">
                      <div className="pdf-logo">
                        <div className="display-img">
                          <img
                            src={pdf}
                            alt="uploaded file"
                            height={80}
                            width={80}
                          />
                        </div>
                        <div>
                          <p>Upload VAT registration certificate (pdf).</p>
                          <span>{uploadFiles["vatCertificate"]?.name}</span>
                        </div>
                      </div>
                      {!uploadFiles["vatCertificate"] ? (
                        <div>
                          <UploadDocuments
                            accept={"application/pdf"}
                            name={"vatCertificate"}
                            id={"vatfileupload"}
                            handleChange={(event: any) => {
                              const file = event.target.files[0];
                              const maxSize = 5 * 1024 * 1024; // 5MB (example limit)
                              if (file.size > maxSize) {
                                setFileSizeExceeded(true);
                                alert(
                                  "File size exceeds the limit. Please choose a smaller file."
                                );
                                return;
                              }
                              setUploadFiles((e) => ({
                                ...e,
                                vatCertificate: file,
                              }));
                            }}
                            uploadFiles={uploadFiles}
                          />
                        </div>
                      ) : (
                        <Button
                          varient="secondary"
                          onClick={() =>
                            setUploadFiles((e) => ({
                              ...e,
                              vatCertificate: undefined,
                            }))
                          }
                        >
                          Delete
                          <Delete />
                        </Button>
                      )}
                    </div>
                  </aside>
                </div>

                {/* CeoDetails */}
                <div className="mx">
                  <aside id="step4">
                    <h2>
                      CEO details<span>.</span>
                    </h2>

                    <div className="ceo-detials">
                      <div className="ceo-input">
                        <Input
                          name="CeoName"
                          type="text"
                          placeholder="Enter name"
                          label="NAME"
                        />
                      </div>
                      <div className="ceo-input">
                        <Input
                          name="CeoSurname"
                          type="text"
                          placeholder="Enter surname"
                          label="SURNAME"
                        />
                      </div>
                      <div className="ceo-input">
                        <Input
                          name="IdNumber"
                          type="number"
                          id="number"
                          placeholder="Enter id number"
                          label="ID NUMBER"
                        />
                      </div>
                    </div>

                    <div>
                      <h6>COPY OF ID</h6>
                      <div className="documents-info">
                        <div className="pdf-logo">
                          <div className="display-img">
                            <img
                              src={pdf}
                              alt="uploaded file"
                              height={80}
                              width={80}
                            />
                          </div>

                          <div>
                            <p>Upload copy of ID (pdf).</p>
                            <span>{uploadFiles["coId"]?.name}</span>
                          </div>
                        </div>
                        {!uploadFiles["coId"] ? (
                          <div>
                            <UploadDocuments
                              accept={"application/pdf"}
                              name={"coId"}
                              id={"fileupload"}
                              handleChange={(event: any) => {
                                const file = event.target.files[0];
                                const maxSize = 5 * 1024 * 1024; // 5MB (example limit)
                                if (file.size > maxSize) {
                                  setFileSizeExceeded(true);
                                  alert(
                                    "File size exceeds the limit. Please choose a smaller file."
                                  );
                                  return;
                                }
                                setUploadFiles((e) => ({
                                  ...e,
                                  coId: file,
                                }));
                              }}
                              uploadFiles={uploadFiles}
                            />
                          </div>
                        ) : (
                          <Button
                            varient="secondary"
                            onClick={() =>
                              setUploadFiles((e) => ({
                                ...e,
                                coId: undefined,
                              }))
                            }
                          >
                            Delete
                            <Delete />
                          </Button>
                        )}
                      </div>
                    </div>
                  </aside>
                </div>
                {/* DirectorsDetails */}
                <div className="mx">
                  <aside id="step5">
                    <FieldArray name="Directors">
                      {(arrayHelpers) => (
                        <div className="director-detials">
                          <h2>
                            Director details<span>.</span>
                          </h2>
                          <div className="director-info">
                            <div className="number">
                              <h5>Directors</h5>
                              <h5>Number</h5>
                              <Button
                                varient="add"
                                type="button"
                                onClick={() => {
                                  arrayHelpers.push({
                                    DirectorsName: "",
                                    DirectorsNumber: "",
                                    DirectorsFiles: "",
                                  });
                                }}
                              >
                                + Add
                              </Button>
                            </div>
                            <div>
                              <div>
                                {values.Directors.map((director, index) => (
                                  <UploadDocumentFiles
                                    key={index}
                                    directors={directors}
                                    setDirectors={setDirectors}
                                    setFileSizeExceeded={setFileSizeExceeded}
                                    index={index}
                                  />
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </aside>
                </div>
                <div className="submit-btn">
                  <Link to={`/company`}>
                    <Button varient="secondary">cancel</Button>
                  </Link>
                  <Button
                    varient="secondary"
                    type="submit"
                    disabled={isSubmitting}
                    className="upload-btn"
                    onClick={toggleModal}
                  >
                    {isSubmitting ? "Loading..." : "Upload"}
                  </Button>
                  {isSubmitting && <MOdalPopUp />}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </StepLayout>
  );
};

export default CompanyDetailForm;
