import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Company.scss";
import Layout from "../../components/Layout";
import { query, where } from "firebase/firestore/lite";
import { db } from "../../utils/firebase";
import { collection, getDocs } from "firebase/firestore/lite";
import { useDataStore } from "../../store/dataStore";
import { userStore } from "../../store/userStore";
import MoblieHeader from "../../components/MobileHeader";
import Loading from "../../components/Loading";
import AddCompanyContinue from "../../components/AddCompanyContinue";
import Plus from "../../assets/icons/plus.png";
import { ReactComponent as Building } from "../../assets/icons/building.svg";
import Fallback from "../../assets/icons/pdf.svg";

const CompanyHomePage: React.FC = () => {
  const data = useDataStore((state) => state.data);
  const setData = useDataStore((state) => state.setData);
  const user = userStore((state) => state.user);
  const [isloading, setIsLoading] = useState(true);

  const addImageFallback = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    let imgElement = event.target as HTMLImageElement;
    imgElement.src = Fallback;
    imgElement.alt = "Fallback-Image";
  };

  const fetchData = useCallback(async () => {
    if (!user) return;
    try {
      setIsLoading(true);
      const q = query(
        collection(db, "companyInfo"),
        where("userId", "==", user.uid)
      );
      const querySnapshot = await getDocs(q);
      console.log(querySnapshot);

      const fetchedData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(fetchedData);

      console.log("fetchedData", fetchedData);
    } catch (error) {
      console.error("Error fetching Firestore data:", error);
    } finally {
      setIsLoading(false); // Reset loading state to false
    }
  }, [user, setData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <Layout>
        <MoblieHeader />

        <div className="company-head">
          <p>Company</p>
        </div>
        {isloading ? (
          <Loading />
        ) : (
          <div>
            {data.length === 0 ? (
              <AddCompanyContinue />
            ) : (
              <div>
                <div className="add-new-company">
                  <Link to="/company-details">
                    <div className="border">
                      <div className="backgrund-icon">
                        <img src={Plus} alt="" />
                      </div>

                      <div className="add-new-company-para">
                        <div className="building-circle flex-content">
                          <Building />
                        </div>
                        <p>Add new company</p>
                      </div>
                    </div>
                  </Link>
                </div>

                <div className="add-company-container-view">
                  {data.map((item) => {
                    return (
                      <Link
                        to={encodeURIComponent(item.CompanyName)}
                        key={item.id}
                        className="card"
                      >
                        <div>
                          <div className="add-company-content flex-content">
                            <img
                              src={item.bannerImage}
                              alt=""
                              onError={addImageFallback}
                            />
                          </div>
                          <div className="files-end-content">
                            <div className="pdf-file">
                              <div className="logo">
                                <img
                                  src={item.logo}
                                  alt=""
                                  onError={addImageFallback}
                                />
                              </div>

                              <div className="content">
                                <h6>{item.CompanyName}</h6>
                                <p>{item.CompanyAddress}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </Layout>
    </div>
  );
};

export default CompanyHomePage;
