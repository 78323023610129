import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import autoAnimate from "@formkit/auto-animate";
import "./MobileHeader.scss";
import { signOut } from "firebase/auth";
import { headerStore } from "../../store/headerStore";
import { useDataStore } from "../../store/dataStore";
import { auth } from "../../utils/firebase";
import { userStore } from "../../store/userStore";
import Button from "../Button";
import Close from "../../assets/icons/close.svg";
import { ReactComponent as TopChevronDown } from "../../assets/icons/top-chevron-down.svg";
import { ReactComponent as Building } from "../../assets/icons/building.svg";
import Logo from "../../assets/icons/moblie-responsive-logo.png";
import Menu from "../../assets/icons/menu.png";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";

const MoblieHeader: React.FC = () => {
  const [openToggle, setOpenToggle] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const parent = useRef(null);
  const data = useDataStore((state) => state.data);
  const user = userStore((s) => s.user);

  const navigate = useNavigate();

  const isScrolled = headerStore((store) => store.isScrolled);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const handleOpenToggle = () => {
    setOpenToggle(!openToggle);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      const user = auth.currentUser;
      if (!user) {
        navigate("/login");
        console.log("Signed out successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (openClose) {
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflowY = "initial";
      document.body.style.height = "initial";
    }
  }, [openClose]);

  return (
    <div className="company-header">
      {!isScrolled ? (
        <div className="media-header">
          <Link to="/">
            <div className="media-logo">
              <img src={Logo} alt="" />
            </div>
          </Link>
          <div onClick={() => setOpenClose((m) => !m)} className="menu">
            <img src={Menu} alt="" />
          </div>
        </div>
      ) : null}

      <AnimatePresence>
        {openClose && (
          <motion.div
            className="sidebar-backdrop"
            onClick={() => setOpenClose(false)}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            <motion.div
              className="bar"
              onClick={(e: any) => e.stopPropagation()}
              animate={{ right: 0, transitionDelay: "-200ms" }}
              exit={{ right: -300 }}
              initial={{ right: -300 }}
            >
              <div className="header-side-bar">
                <div className="close-icon" onClick={() => setOpenClose(false)}>
                  <img src={Close} alt="" />
                </div>

                <div className="profile-content">
                  <div className="profile">
                    <p>{user?.displayName?.slice(0, 1)}</p>
                  </div>
                  <h6>{user?.displayName}</h6>
                  <p>{user?.email}</p>
                  <Button
                    varient="primary"
                    leftIcon={<Logout />}
                    onClick={handleLogout}
                  >
                    Log out
                  </Button>
                </div>
                <div className="border"></div>

                <div
                  className={`company-drop-down-content  ${
                    openToggle ? "active" : ""
                  }`}
                  onClick={handleOpenToggle}
                >
                  <div className="media-building">
                    <div>
                      <Building />
                    </div>
                    <Link to="/company">
                      <p>Company</p>
                    </Link>
                  </div>
                  <div>
                    <TopChevronDown
                      className={`drop-down-icon ${openToggle ? "rotate" : ""}`}
                    />
                  </div>
                </div>
                <div ref={parent}>
                  {openToggle && (
                    <div className="company-name">
                      <div>
                        {data.map((f, index) => {
                          return (
                            <div
                              key={index}
                              className="link"
                              onClick={() => setOpenClose(false)}
                            >
                              <Link
                                to={`/company/${encodeURIComponent(
                                  f.CompanyName
                                )}`}
                              >
                                <p>{f.CompanyName}</p>
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default MoblieHeader;
