import React, { useEffect, useState } from "react";
import axios from "axios";
import { generateSignature, getData } from "../../utils/helpers";

const PayfastPayment: React.FC = () => {
  const [data, setData] = useState({
    merchant_id: "10030200",
    merchant_key: "7bswhqj1d92f0",
    return_url: "http://localhost:3000/success",
    cancel_url: "http://localhost:3000/cancel",
    notify_url:
      "https://779b-2405-201-e006-88ab-d82e-b8e0-7fae-9de6.ngrok-free.app/notify",
    name_first: "John",
    email_address: "john@doe.com",
    m_payment_id: "0x1234567890545",
    amount: "100.00",
    item_name: "Test Product",
    signature: "",
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    // const actionUrl = "https://www.payfast.co.za/eng/process";
    const actionUrl = "https://sandbox.payfast.co.za/eng/process";

    const passphrase = "testingpurpose";
    const signature = generateSignature(data, passphrase);
    console.log("data", signature);

    setData((d) => ({ ...d, signature: signature }));

    await new Promise((res) => setTimeout(res, 2000));

    event.target.action = actionUrl;
    event.target.submit();
  };

  useEffect(() => {
    const pfValidServerConfirmation = async (
      pfHost: string,
      pfParamString: string
    ) => {
      try {
        const response = await axios.post(
          "https://sandbox.payfast.co.za/eng/query/validate",
          pfParamString
        );
        const result = response.data;
        return result === "VALID";
      } catch (error) {
        console.error(error);
        return false;
      }
    };

    const pfParamString = getData(data);

    pfValidServerConfirmation("sandbox.payfast.co.za", pfParamString).then(
      (isValid) => {
        console.log("Server confirmation:", isValid);
      }
    );
  }, [data]);

  return (
    <form onSubmit={handleSubmit}>
      <input type="hidden" name="merchant_id" value={data.merchant_id} />
      <input type="hidden" name="merchant_key" value={data.merchant_key} />
      <input type="hidden" name="return_url" value={data.return_url} />
      <input type="hidden" name="cancel_url" value={data.cancel_url} />
      <input type="hidden" name="notify_url" value={data.notify_url} />
      <input type="hidden" name="name_first" value={data.name_first} />
      <input type="hidden" name="email_address" value={data.email_address} />
      <input type="hidden" name="m_payment_id" value={data.m_payment_id} />
      <input type="hidden" name="amount" value={data.amount} />
      <input type="hidden" name="item_name" value={data.amount} />
      {/* <input type="text" name="signature" value={data.signature} /> */}
      <input type="submit" value="PAY NOW" />
    </form>
  );
};

export default PayfastPayment;
