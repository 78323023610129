import React, { useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./PricingPlans.scss";
import Button from "../../components/Button";
import { DemoPricingPlans } from "./PricingPlans";
import RigthArrow from "../../assets/icons/rigthArrow.svg";
import LeftArrow from "../../assets/icons/leftArrow.svg";

const PricingPlans: React.FC = () => {
  const ref = useRef<Slider>(null);
  const [isFirstSlide, setIsFirstSlide] = useState(true);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [isModalOpen, setModalState] = useState(false);
  const toggleModal = () => setModalState(!isModalOpen);

  var settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
    autoplay: true,
    fade: true,
    beforeChange: (next: any) => {
      setIsFirstSlide(next === 0);
      setIsLastSlide(next === DemoPricingPlans.length - 1);
    },
  };

  const goToPrevSlide = () => {
    ref.current?.slickPrev();
  };

  const goToNextSlide = () => {
    ref.current?.slickNext();
  };

  return (
    <div className="pricing-plans-wrapper">
      <div className="mx">
        <div className="ubucon-img">
          <div className="ubucon-container">
            <h4>Pricing & plans</h4>
          </div>

          <div className="slideshow-wrapper">
            <Slider ref={ref} {...settings}>
              {DemoPricingPlans.map((slide, index) => {
                return (
                  <div key={index} className="slide-card">
                    <div>
                      <h4>{slide.title}</h4>
                      <h4>{slide.tittle2}</h4>
                      <h5>{slide.price}</h5>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>

          <div className="reg-btn">
            <Link to="/login">
              <Button varient="primary" onClick={toggleModal}>
                Register
              </Button>
            </Link>
          </div>
          <div className="moblie-head">
            <h4>Pricing & plans</h4>
          </div>

          <div className="slide-arrows">
            <div
              className={`left-arrow ${isFirstSlide ? "disabled" : ""}`}
              style={{ display: isFirstSlide ? "block" : "none" }}
              onClick={goToPrevSlide}
            >
              <img src={LeftArrow} alt="" />
            </div>
            <div
              className={`right-arrow ${isLastSlide ? "disabled" : ""}`}
              style={{ cursor: isLastSlide ? "not-allowed" : "pointer" }}
              onClick={goToNextSlide}
            >
              <img src={RigthArrow} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPlans;
