import React, { useEffect, useRef, useState } from "react";
import { Form, Formik, FieldArray, Field } from "formik";
import autoAnimate from "@formkit/auto-animate";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { v4 } from "uuid";
import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore/lite";
import "./SafetyFile.scss";
import "../../../components/MobileHeader/MobileHeader.scss";
import Input from "../../../components/Input";
import Header from "../../../components/Header";
import Button from "../../../components/Button";
import { db, storage } from "../../../utils/firebase";
import { userStore } from "../../../store/userStore";
import SelectFilesToAddDetails from "../SelectFilesToAddDetails";
import Operator from "../../../components/Operator";
import { ICompany } from "../../../utils/types";
import MOdalPopUp from "../../../components/ModalPopupBox";
import { DocumentValidationScema } from "../../../utils/validationSchema";
import CompanyFormHeader from "../../CompanySection/component/CompanyFormHeader";
import { data, generateSignature } from "../../../utils/helpers";
import { SafetyFileNameData } from "../../../data/saftyFileData";
import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../../../assets/icons/minus.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete-icon.svg";

const initialState = {
  projectName: "",
  ceoName: "",
  clientName: "",
  commencementdate: "",
  operators: [
    { operatorName: "", operatorNumber: "", ConstructionVehicle: "" },
  ],
  appointment: [
    { appointmentName: "", appointmentNumber: "", appointment: "" },
  ],
  officers: SafetyFileNameData.map((f, i) => {
    return { name: "", phoneNumber: "", url: "" };
  }),
};

interface UploadFiles {
  developer?: File;
  director?: File;
  manager?: File;
  Assistantmanager?: File;
  compilance?: File;
  Supervisor?: File;
}

const SafetyFile: React.FC<ICompany> = ({ id: companyId }) => {
  const [uploadFiles] = useState<UploadFiles>({});
  const [show, setShow] = useState(false);
  const user = userStore((s) => s.user);
  const userData = userStore((s) => s.userData);
  const [showError, setShowError] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([]);
  const [constructionVehicle, setConstructionVehicle] = useState<
    { file: File; index: number }[]
  >([]);
  const [appointments, setappointment] = useState<
    { file: File; index: number }[]
  >([]);
  const [officer, setOfficer] = useState<{ file: File; index: number }[]>([]);
  console.log(officer);

  const handleShow = (show: boolean) => {
    if (selectedPermissions.length > 0) {
      setShow(show);
    } else {
      setShowError("*Should be select either one checkbox");
    }
  };
  console.log(show);

  const parent = useRef(null);

  const handlePermissionSelect = (permission: string) => {
    if (selectedPermissions.includes(permission)) {
      setSelectedPermissions(
        selectedPermissions.filter((p) => p !== permission)
      );
    } else {
      setSelectedPermissions([...selectedPermissions, permission]);
    }
  };
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const handleSubmit = async (values: typeof initialState) => {
    console.log(values);
    try {
      if (!user) return;

      let urls = {};
      const uuid = uuidv4();

      const docRefe = doc(db, "Payments", uuid);
      await setDoc(docRefe, {
        userId: user?.uid,
        userName: user?.displayName,
        created: serverTimestamp(),
      });

      const files = Object.entries(uploadFiles).map((d) => d);

      await Promise.all(
        files.map(async (file) => {
          const imageref = ref(storage, `Documents/${v4()}`);
          const uploadTask = uploadBytesResumable(imageref, file[1]);
          await uploadTask;
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log("Download URL:", downloadURL);
          urls = { ...urls, [file[0]]: downloadURL };
        })
      );
      const operatorUrls = await Promise.all(
        constructionVehicle.map(async (f) => {
          const imageref = ref(storage, `Documents/${+v4()}`);

          const uploadTask = uploadBytesResumable(imageref, f.file);

          await uploadTask;

          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log("Download URL:", downloadURL);
          return { index: f.index, ConstructionVehicle: downloadURL };
        })
      );
      const newOperatorData = values.operators.map((m, i) => {
        const data = operatorUrls.find((f) => f.index === i);
        if (data) {
          return { ...m, url: data.ConstructionVehicle };
        }

        return {
          ...m,
        };
      });
      console.log(newOperatorData);

      const appoinmentUrl = await Promise.all(
        appointments.map(async (f) => {
          const imageref = ref(storage, `Documents/${v4()}`);

          const uploadTask = uploadBytesResumable(imageref, f.file);

          await uploadTask;

          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log("Download URL:", downloadURL);
          return { index: f.index, appointment: downloadURL };
        })
      );
      const newAppoinmentData = values.appointment.map((m, i) => {
        const data = appoinmentUrl.find((f) => f.index === i);
        if (data) {
          return { ...m, url: data.appointment };
        }

        return {
          ...m,
        };
      });
      console.log(newAppoinmentData);

      const officersUrl = await Promise.all(
        officer.map(async (f) => {
          const imageref = ref(storage, `Documents/${v4()}`);
          const uploadTask = uploadBytesResumable(imageref, f.file);
          await uploadTask;

          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log("Download URL:", downloadURL);
          return { index: f.index, url: downloadURL };
        })
      );

      const newOfficerData = values.officers.map((m, i) => {
        const data = officersUrl.find((f) => f.index === i);
        if (data) {
          return { ...m, url: data.url };
        }
        return {
          ...m,
        };
      });

      console.log(newOfficerData);

      await addDoc(collection(db, "Documents"), {
        ...values,
        ...urls,
        userId: user.uid,
        companyId: companyId,
        referenceId: uuid,
        pdfs: selectedPermissions,
        officers: newOfficerData,
        operators: newOperatorData,
        appointment: newAppoinmentData,
      });

      const form = document.createElement("form");
      document.body.appendChild(form);

      const formattedData = {
        ...data,
        return_url: `${window.location.origin}/success?replace_url=${
          window.location.href.split("/buy")[0]
        }`,
        cancel_url: `${window.location.origin}/cancel?replace_url=${
          window.location.href.split("/buy")[0]
        }`,
        notify_url: "https://ubuconbackend.onrender.com/payfast/notify",
        name_first: user?.displayName,
        email_address: user?.email,
        m_payment_id: uuid,
        amount: userData?.isNewUser === false ? "1699" : "999",
        item_name: "arun",
        signature: "",
      };

      const passphrase = process.env.REACT_APP_PASSPHRASE;

      if (!passphrase) throw new Error("PASSPHRASE INVALID");

      const signature = generateSignature(formattedData, passphrase);

      const newFormattedData = {
        ...formattedData,
        signature,
      };

      Object.entries(newFormattedData).map(([name, value]) => {
        const input = document.createElement("input");
        document.body.appendChild(input);
        form.appendChild(input);
        input.type = "hidden";
        input.name = name;
        input.value = value as string;
        return null;
      });

      const actionUrl = "https://www.payfast.co.za/eng/process";
      form.action = actionUrl;
      form.submit();
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  return (
    <div className="mx">
      <div className="safety-file-header">
        <Header />
      </div>
      <CompanyFormHeader />

      <div className="safety-file-wrapper">
        <div className="safety-file-heading">Safety file</div>
        <Formik
          initialValues={initialState}
          onSubmit={handleSubmit}
          validationSchema={DocumentValidationScema}
        >
          {({ values, isSubmitting }) => (
            <Form>
              {!show ? (
                <>
                  <div className="input-first-row">
                    <div>
                      <Input
                        name="projectName"
                        type="text"
                        placeholder="Enter project name"
                        label="Project name"
                      />
                    </div>
                    <div>
                      <Input
                        name="ceoName"
                        type="text"
                        placeholder="Enter CEO name"
                        label="CEO name"
                      />
                    </div>
                    <div>
                      <Input
                        name="clientName"
                        type="text"
                        placeholder="Enter client name"
                        label="Client name"
                      />
                    </div>
                    <div>
                      <Input
                        name="commencementdate"
                        type="date"
                        placeholder="Select date"
                        label="Commencement date"
                      />
                    </div>
                    <div>
                      <Input
                        name="completiondate"
                        type="date"
                        placeholder="Select date"
                        label="Completion date"
                      />
                    </div>
                  </div>
                  <div className="table-Wrapper">
                    <div className="table-head">
                      <p>Title</p>
                      <p>Name</p>
                      <p>ID Number</p>
                      <p>CV/Ceritificates</p>
                    </div>
                    <div className="table-container">
                      {values.officers.map((f, index) => {
                        return (
                          <div className="table-content" key={index}>
                            <div className="table-flex-content">
                              <p>{SafetyFileNameData[index]}</p>
                            </div>
                            <div>
                              <Input
                                name={`officers[${index}].name`}
                                type="text"
                                placeholder="Enter name"
                              />
                            </div>
                            <div>
                              <Input
                                name={`officers[${index}].phoneNumber`}
                                type="number"
                                id="number"
                                placeholder="Enter number"
                              />
                            </div>
                            {officer.find((f) => f.index === index) ? (
                              <Button
                                varient="secondary"
                                onClick={() =>
                                  setOfficer((e) => [
                                    ...e.filter((f) => f.index !== index),
                                  ])
                                }
                              >
                                Delete
                                <Delete />
                              </Button>
                            ) : (
                              <div>
                                <label
                                  htmlFor={`officer_${index}`}
                                  className="custom-file-upload"
                                >
                                  <Upload />
                                  Upload
                                  <Field
                                    id={`officer_${index}`}
                                    name={`url${index}`}
                                    type="file"
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      const files = e.target.files;
                                      if (!files || !files.length) return;

                                      if (e.target.files !== null) {
                                        setOfficer((c) => [
                                          ...c,
                                          {
                                            file: files[0],
                                            index: index,
                                          },
                                        ]);
                                      }
                                      const file = files[0];
                                      const maxSize = 2 * 1024 * 1024; // 5MB (example limit)
                                      if (file.size > maxSize) {
                                        alert(
                                          "File size exceeds the limit. Please choose a smaller file."
                                        );
                                        return;
                                      }
                                    }}
                                    accept="application/pdf, "
                                    style={{
                                      border: "none",
                                      outline: "none",
                                    }}
                                  />
                                </label>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="plant-operator-wrapper">
                    <FieldArray name="operators">
                      {(arrayHelpers) => (
                        <div>
                          <div className="plant-operator-head">
                            <div>
                              <p>Construction Vehicle and Plant Operator</p>
                            </div>
                            <div className="add-btn">
                              <Button
                                varient="primary"
                                leftIcon={<Plus />}
                                onClick={() => {
                                  arrayHelpers.push({
                                    operatorName: "",
                                    operatorNumber: "",
                                    ConstructionVehicle: "",
                                  });
                                }}
                              >
                                Add
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    </FieldArray>
                    <div ref={parent}>
                      {values.operators.map((Operators, index) => {
                        return (
                          <div key={index}>
                            <Operator
                              constructionVehicle={constructionVehicle}
                              setConstructionVehicle={setConstructionVehicle}
                              index={index}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="plant-operator-wrapper">
                    <FieldArray name="appointment">
                      {(filedArrayProps) => (
                        <div>
                          <div className="plant-operator-head">
                            <div>
                              <p>Flagman appointment</p>
                            </div>
                            <div className="add-btn">
                              <Button
                                varient="primary"
                                leftIcon={<Plus />}
                                onClick={() => {
                                  filedArrayProps.push({
                                    appointmentName: "",
                                    appointmentNumber: "",
                                    appointment: "",
                                  });
                                }}
                              >
                                Add
                              </Button>
                            </div>
                          </div>
                          <div ref={parent}>
                            {values.appointment.map((appointment, index) => (
                              <div
                                key={index}
                                className="plant-operator-inputs"
                              >
                                <div>
                                  <Input
                                    name={`appointment[${index}].appointmentName`}
                                    placeholder="Enter name"
                                    type="text"
                                    className="inputCom"
                                  />
                                </div>
                                <div>
                                  <Input
                                    name={`appointment[${index}].appointmentNumber`}
                                    placeholder="Enter number"
                                    type="number"
                                    id="number"
                                    className="inputCom"
                                  />
                                </div>
                                <div className="upload-content">
                                  {appointments &&
                                  appointments.find(
                                    (f) => f.index === index
                                  ) ? (
                                    <Button
                                      onClick={() => {
                                        setappointment((c) => {
                                          // Filter out the item with the current index
                                          filedArrayProps.remove(index);
                                          return c.filter(
                                            (item) => item.index !== index
                                          );
                                        });
                                      }}
                                      varient="secondary"
                                    >
                                      Delete <Delete />
                                    </Button>
                                  ) : (
                                    <div>
                                      <label
                                        htmlFor={`supervisor_${index}`}
                                        className="custom-file-upload"
                                      >
                                        <Upload />
                                        Upload
                                        <Field
                                          id={`supervisor_${index}`}
                                          name={`supervisorLogo_${index}`}
                                          type="file"
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) => {
                                            const files = e.target.files;
                                            if (!files || !files.length) return;

                                            if (e.target.files !== null) {
                                              setappointment((c) => [
                                                ...c,
                                                {
                                                  file: files[0],
                                                  index: index,
                                                },
                                              ]);
                                            }
                                            const file = files[0];
                                            const maxSize = 5 * 1024 * 1024; // 5MB (example limit)
                                            if (file.size > maxSize) {
                                              alert(
                                                "File size exceeds the limit. Please choose a smaller file."
                                              );
                                              return;
                                            }
                                          }}
                                          accept="application/pdf, "
                                          style={{
                                            border: "none",
                                            outline: "none",
                                          }}
                                        />
                                      </label>
                                    </div>
                                  )}

                                  <div className="minus">
                                    <Minus
                                      onClick={() => {
                                        setappointment((c) => {
                                          // Filter out the item with the current index
                                          filedArrayProps.remove(index);
                                          return c.filter(
                                            (item) => item.index !== index
                                          );
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </>
              ) : (
                <SelectFilesToAddDetails
                  handlePermissionSelect={handlePermissionSelect}
                  selectedPermissions={selectedPermissions}
                  permission={""}
                  showError={showError}
                  handleShow={function (show: boolean): void {
                    throw new Error("Function not implemented.");
                  }}
                />
              )}
              <div className="safety-file-btns">
                <Button
                  varient="secondary"
                  disabled={isSubmitting}
                  onClick={() => setShow(true)}
                >
                  Back
                </Button>
                <Button
                  varient="primary"
                  type="submit"
                  onClick={() => handleShow(true)}
                >
                  {isSubmitting ? "Loading.." : "Next"}
                </Button>
                {isSubmitting && <MOdalPopUp />}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SafetyFile;
