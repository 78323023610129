import React from "react";
import {useState} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import Button from "../../components/Button";

interface Values {
  name: string;
  email: string;
}

const NewsletterForm: React.FC = () => {
  const [subscribed, setSubscribed] = useState(false);
  const initialValues: Values = {
    name: "",
    email: "",
  };

  const handleSubmit = async (values: Values) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const docRef = await addDoc(collection(db, "subscribers"), { ...values });
      setSubscribed(true);
      // Perform any additional actions after successful submission
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="newsletter" style={{ padding: "10px" }}>
      <p>Newsletter</p>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        <Form>
          <label htmlFor="name" style={{ padding: "10px" }}>Name:</label>
          <Field style={{ padding: "10px" }} type="text" id="name" name="name" placeholder="Enter your Name" />
          <ErrorMessage name="name" component="div" className="errorMsg" />

          <label style={{ padding: "10px" }} htmlFor="email">Email:</label>
          <Field style={{ padding: "10px" }} type="email" id="email" name="email" placeholder="Enter your Email Address" />
          <ErrorMessage name="email" component="div" className="errorMsg" />

          <Button varient="primary" type="submit" style={{ padding: "10px", marginTop: "10px" }}>Subscribe</Button>
        </Form>
      </Formik>
      {subscribed && <p>Thank you for subscribing!</p>}
    </div>
  );
};

export default NewsletterForm;