import React from "react";
import Layout from "../Layout";

const ComingSoon: React.FC = () => {
  return (
    <Layout>
      <div className="coming-soon-content">
        <h1>ComingSoon</h1>
      </div>
    </Layout>
  );
};

export default ComingSoon;
