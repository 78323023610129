import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import "./UbuconSection.scss";
import { UbuconData, UbuconDataThree, UbuconDataTwo } from "./Ubucon";
import shadow from "../../assets/icons/shadow.svg";

const UbuconSection: React.FC = () => {
  const [active, setActive] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActive((prevActive) => (prevActive + 1) % UbuconData.length);
    }, 3000); // Adjust the interval time (in milliseconds) as desired

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="ubucon-section-wrapper">
      <div className="mx">
        <div className="ubucon-section-content-left">
          <div className="content">
            <h4>Who we are</h4>
            <div className="content-section">
              <div>
                <div className="memories">
                  <div className="memories-card_wrapper">
                    {UbuconData.map((f, index) => (
                      <motion.div
                        key={index.toString()}
                        className={
                          index === active
                            ? "memories-card active"
                            : "memories-card"
                        }
                        initial={{ scale: 0, x: -10 }}
                        animate={{
                          x: 0,

                          scale: index === active ? 1 : 0.9,
                          opacity: index === active ? 1 : 0.7,
                          rotate:
                            active + 1 === index
                              ? 0
                              : active === 1
                              ? UbuconData.length - 1 === index
                                ? 0
                                : 0
                              : 0,

                          zIndex: index === active ? 1 : 0,
                          left:
                            index === active
                              ? 0
                              : active + 1 === index
                              ? 40
                              : active === 0
                              ? UbuconData.length - 1 === index
                                ? -40
                                : -40
                              : -40
                              ? -40
                              : 0,
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 200,
                          damping: 30,
                        }}
                      >
                        <div
                          className="image"
                          style={{
                            backgroundImage: `url(${f.image})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            width: "100%",
                            aspectRatio: "16/9",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          ></div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>
                <img
                  src={shadow}
                  className="shadow-img"
                  alt="shadow"
                  width={380}
                  height={53}
                />
              </div>
              <div>
                <p>
                  UBUcon is an online software company offering self service online software
                  for all SMME’s. At UBUcon, we are equipped with a team of
                  professionals all actively involved within the construction
                  and contracting industry, ensuring all services, data and
                  information are always accurate and up to date. We believe in
                  taking hands Empowering SMME’s across South Africa.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ubucon-section-content-right">
          <div className="content">
            <h4 className="title-window">What we do</h4>

            <div className="content-section">
              <div>
                <div className="memories">
                  <div className="memories-card_wrapper">
                    {UbuconDataTwo.map((f, index) => (
                      <motion.div
                        key={index.toString()}
                        className={
                          index === active
                            ? "memories-card active"
                            : "memories-card"
                        }
                        initial={{ scale: 0, x: -10 }}
                        animate={{
                          x: 0,

                          scale: index === active ? 1 : 0.9,
                          opacity: index === active ? 1 : 0.7,
                          rotate:
                            active + 1 === index
                              ? 0
                              : active === 1
                              ? UbuconData.length - 1 === index
                                ? 0
                                : 0
                              : 0,

                          zIndex: index === active ? 1 : 0,
                          left:
                            index === active
                              ? 0
                              : active + 1 === index
                              ? 40
                              : active === 0
                              ? UbuconData.length - 1 === index
                                ? -40
                                : -40
                              : -40
                              ? -40
                              : 0,
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 200,
                          damping: 30,
                        }}
                      >
                        <div
                          className="image"
                          style={{
                            backgroundImage: `url(${f.image})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            width: "100%",
                            aspectRatio: "16/9",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          ></div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>
                <img
                  src={shadow}
                  className="shadow-img"
                  alt="shadow"
                  width={380}
                  height={53}
                />
              </div>
              <div className="ubu-content-wrapp">
                <h4 className="title-mobile">What we do</h4>

                <p>
                  UBUcon provides a platform with affordable easy-to-use online
                  software to create your own safety file with just the click of
                  a button. UBUcon will provide a one-stop for all your
                  day-to-day business functions like invoicing, quotes, and
                  payroll using any device ( PC, Laptop, Cellphone, or Tablet).
                  At UBUcon you will find all the Templates for your day-to-day
                  management functions like Timesheets, Daily Diaries,
                  Measurement Sheets, and many more.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ubucon-section-content-left">
          <div className="content">
            <h4>Why UBUcon</h4>
            <div className="content-section">
              <div>
                <div className="memories">
                  <div className="memories-card_wrapper">
                    {UbuconDataThree.map((f, index) => (
                      <motion.div
                        key={index.toString()}
                        className={
                          index === active
                            ? "memories-card active"
                            : "memories-card"
                        }
                        initial={{ scale: 0, x: -10 }}
                        animate={{
                          x: 0,

                          scale: index === active ? 1 : 0.9,
                          opacity: index === active ? 1 : 0.7,
                          rotate:
                            active + 1 === index
                              ? 0
                              : active === 1
                              ? UbuconData.length - 1 === index
                                ? 0
                                : 0
                              : 0,

                          zIndex: index === active ? 1 : 0,
                          left:
                            index === active
                              ? 0
                              : active + 1 === index
                              ? 40
                              : active === 0
                              ? UbuconData.length - 1 === index
                                ? -40
                                : -40
                              : -40
                              ? -40
                              : 0,
                        }}
                        transition={{
                          type: "spring",
                          stiffness: 200,
                          damping: 30,
                        }}
                      >
                        <div
                          className="image"
                          style={{
                            backgroundImage: `url(${f.image})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            width: "100%",
                            aspectRatio: "16/9",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          ></div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>
                <img
                  src={shadow}
                  className="shadow-img"
                  alt="shadow"
                  width={380}
                  height={53}
                />
              </div>
              <div>
                <p>
                  We provide a user-friendly platform to SMME’s enabling them to
                  run an effective and compliant business.
                </p>
                <h5>UP TO DATE - VALID - COMPLIANT - ALWAYS</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UbuconSection;
