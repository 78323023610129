import React from "react";
import { Field, FieldArray } from "formik";
import Button from "../../../../components/Button";
import Input from "../../../../components/Input";
import { ReactComponent as Minus } from "../../../../assets/icons/minus.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/icons/upload.svg";
import { ReactComponent as Delete } from "../../../../assets/icons/delete.svg";

interface IUploadFiles {
  index: number;
  directors: {
    file: File;
    index: number;
  }[];
  setDirectors: React.Dispatch<
    React.SetStateAction<
      {
        file: File;
        index: number;
      }[]
    >
  >;
  setFileSizeExceeded: React.Dispatch<React.SetStateAction<boolean>>;
}
const UploadDocumentFiles: React.FC<IUploadFiles> = ({
  index,
  directors,
  setDirectors,
  setFileSizeExceeded,
}) => {
  return (
    <div>
      <FieldArray name="Directors">
        {(arrayHelpers) => (
          <div className="nameSection">
            <div className="nameSection-one">
              <Input
                name={`Directors[${index}].DirectorsName`}
                type="text"
                placeholder="Enter name"
              />
            </div>
            <div className="nameSection-one">
              <Input
                name={`Directors[${index}].DirectorsNumber`}
                type="number"
                id="number"
                placeholder="Enter id number"
              />
            </div>
            <div className="upload-mobile">
              {directors && directors.find((s) => s.index === index) ? (
                <Button
                  onClick={() => {
                    setDirectors((c) => {
                      // Filter out the item with the current index
                      arrayHelpers.remove(index);
                      return c.filter((item) => item.index !== index);
                    });
                  }}
                  varient={"secondary"}
                >
                  Delete <Delete />
                </Button>
              ) : (
                <div>
                  <label
                    htmlFor={`directorDetials_${index}`}
                    className="custom-file-upload"
                  >
                    <UploadIcon />
                    Upload
                    <Field
                      id={`directorDetials_${index}`}
                      name={`DirectorsFiles_${index}`}
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const files = e.target.files;
                        if (!files || !files.length) return;

                        if (e.target.files !== null) {
                          setDirectors((c) => [
                            ...c,
                            {
                              file: files[0],
                              index: index,
                            },
                          ]);
                        }
                        const file = files[0];
                        const maxSize = 5 * 1024 * 1024; // 5MB (example limit)

                        // Check if file size exceeds the limit
                        if (file.size > maxSize) {
                          setFileSizeExceeded(true);
                          alert(
                            "File size exceeds the limit. Please choose a smaller file."
                          );
                          return;
                        }
                      }}
                      accept="application/pdf"
                      style={{
                        border: "none",
                        outline: "none",
                      }}
                    />
                  </label>
                </div>
              )}

              <div className="minus" style={{ cursor: "pointer" }}>
                <Minus
                  onClick={() => {
                    setDirectors((c) => {
                      // Filter out the item with the current index
                      arrayHelpers.remove(index);
                      return c.filter((item) => item.index !== index);
                    });
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default UploadDocumentFiles;
