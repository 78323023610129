import { create } from "zustand";

type State = {
  data: any[];
};

type Actions = {
  setData: (data: any[]) => void;
};

export const useDataStore = create<State & Actions>((set) => ({
  data: [],
  setData: (data) => set({ data }),
}));
