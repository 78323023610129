import React, { Fragment, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./Lander/Home";
import ComingSoon from "./components/ComingSoon";
import CompanyFiles from "./pages/CompanyDetailsPage/CompanyFiles";
import { auth } from "./utils/firebase";
import { userStore } from "./store/userStore";
import CompanyHomePage from "./pages/Company/Home";
import CompanyDetailsPage from "./pages/CompanyDetailsPage";
import PayfastPayment from "./components/PayfastPayment";
import RegisterModal from "./components/RegisterModal/RegisterModal";
import SplashScreen from "./components/SplashScreen";
import PaymentSuccess from "./components/PaymentSuccess";
import PaymentCancel from "./components/PaymentCancel";
import CompanyDetailForm from "./pages/CompanySection";

const App: React.FC = () => {
  const updateUser = userStore((store) => store.updateUser);
  useEffect(() => {
    return onAuthStateChanged(auth, (data) => {
      if (data) {
        updateUser(data);
      } else {
        // no user
      }
    });
  }, [updateUser]);

  return (
    <Fragment>
      <SplashScreen />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/company-details"
          element={
            <CompanyDetailForm
              id={""}
              isModalOpen={false}
              toggleModal={function (): void {}}
            />
          }
        />
        <Route
          path="/company-details/companyfiles"
          element={<CompanyFiles id={""} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/terms&condition" element={<RegisterModal />} />

        <Route path="/company" element={<CompanyHomePage />} />
        <Route
          path="/company/:company_name/*"
          element={<CompanyDetailsPage />}
        />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/payfast" element={<PayfastPayment />} />
        <Route path="/success" element={<PaymentSuccess />} />
        <Route path="/cancel" element={<PaymentCancel />} />
      </Routes>
    </Fragment>
  );
};

export default App;
