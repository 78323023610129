export const IFiles = [
  {
    title: "Safety file",
    item: "2 Items",
    new: "1 new",
    get path() {
      return this.title.toLowerCase().split(" ").join("-");
    },
  },
];
