import React, { ReactNode } from "react";
import "./AuthLayoutPage.scss";
import Logo from "../../assets/logo/ubu-con-logo-2.png";
import { ReactComponent as PrintingImg } from "../../assets/icons/printing-invoice.svg";

interface AuthLayoutProps {
  children: ReactNode;
}

const AuthLayoutPage: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <div className="auth-layout-wrapper">
      <div className="mx">
        <div className="auth-layout-container">
          <div className="auth-layout-content flex-content">
            <div>
              <div className="logo">
                <img src={Logo} alt="logo" className="logo" />
              </div>
              <div className="printing-img">
                <PrintingImg />
              </div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
          </div>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayoutPage;
