export const SafetyFileNameData = [
  "Fall protection plan developer (optional)",
  "Construction Director",
  "Construction Manager",
  "Assistant Construction Manager",
  "Covid 19 Compliance Officer",
  "Housekeeping Supervisor ",
  "Fire Prevention Supervisor",
  "Construction Supervisor",
  "Assistant Construction Supervisor",
  "Safety Officer",
  "Incident Investigator",
  "Health & Safety Consultant",
  "Health & Safety Rep",
  "First Rider",
  "Stacking and Storage Supervisor",
  "Emergency Evaculation Coordinator",
  "Fall Protection Plan Supervisor",
  "Hand Tools Inspector",
  "Ladder & Access Supervisor",
  "Risk Assessor ",
  "Excavation SuperVisor ",
  "Use & Temporary Storage of Flammable Liquids Supervisor",
  "Fire Fighter Inspector    ",
  "Construction Employees Facilities Supervisor    ",
  "Portable Electrical Tool Inspector    ",
];
