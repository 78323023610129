import React, { useCallback, useEffect, useRef, useState } from "react";
import autoAnimate from "@formkit/auto-animate";
import { collection, getDocs, query } from "firebase/firestore/lite";
import "./SelectFilesToAddDetails.scss";
import Header from "../../../components/Header";
import Checkbox from "../../../components/Checkbox";
import { db } from "../../../utils/firebase";

interface IShowFile {
  handleShow: (show: boolean) => void;
  permission: string;
  handlePermissionSelect: (permission: string) => void;
  selectedPermissions: string[];
  showError: string;
}
interface IData {
  title: string;
  component: string;
}

const SelectFilesToAddDetails: React.FC<IShowFile> = ({
  handleShow,
  handlePermissionSelect,
  selectedPermissions,
  showError,
}) => {
  //auto animate
  const parent = useRef(null);
  const [openClose] = useState(false);
  const [data, setData] = useState<IData[]>([]);

  const handleGetData = useCallback(async () => {
    try {
      const q = query(collection(db, "PDF"));
      const querySnapshot = await getDocs(q);
      const fetchedData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<IData, "id">),
      }));
      console.log(fetchedData);
      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching Firestore data:", error);
    }
  }, [setData]);
  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  useEffect(() => {
    if (openClose) {
      document.body.style.overflowY = "hidden";
      document.body.style.height = "100vh";
    } else {
      document.body.style.overflowY = "initial";
      document.body.style.height = "initial";
    }
  }, [openClose]);

  return (
    <>
      <div className="selectfile-to-details">
        <Header />
      </div>
      <div className="mx">
        <div className="files-add-wrapper">
          <h4>Select files to add details</h4>
          <div className="files-add-container">
            {data.map((permission, index) => (
              <Checkbox
                key={index}
                permission={permission.title}
                handlePermissionSelect={handlePermissionSelect}
                selectedPermissions={selectedPermissions}
              />
            ))}
          </div>
          <div className="errormsg" onChange={() => handleShow(true)}>
            <p>{showError ? "*Should be select either one checkbox" : ""}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectFilesToAddDetails;
