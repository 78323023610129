import AdminUsers from "../../assets/icons/admin-users-svgrepo-com.svg";
import InvoiceWarranty from "../../assets/icons/invoice-warranty-line.svg";
import Markers from "../../assets/icons/markers-page-svgrepo-com.svg";
import ReceivePointer from "../../assets/icons/to-receive-points-svgrepo-com.svg";

export const DemoDataComingsoon = [
  {
    logo: ReceivePointer,
    title: "Payroll support",
  },
  {
    logo: InvoiceWarranty,
    title: "Quotes, purchase orders & Invoicing",
  },
  {
    logo: AdminUsers,
    title: "General site admin templates",
  },
  {
    logo: Markers,
    title: "Logo design & Company stationery setup",
  },
];
