import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../components/Button";
import { Link, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { signOut } from "firebase/auth";
import autoAnimate from "@formkit/auto-animate";
import "../../../../components/MobileHeader/MobileHeader.scss";
import { useDataStore } from "../../../../store/dataStore";
import { headerStore } from "../../../../store/headerStore";
import { userStore } from "../../../../store/userStore";
import { auth } from "../../../../utils/firebase";
import { ReactComponent as Building } from "../../../../assets/icons/building.svg";
import { ReactComponent as LeftArrow } from "../../../../assets/icons/left-arrow.svg";
import { ReactComponent as Logout } from "../../../../assets/icons/logout.svg";
import Menu from "../../../../assets/icons/menu.png";
import Close from "../../../../assets/icons/close.svg";
import { ReactComponent as TopChevronDown } from "../../../../assets/icons/top-chevron-down.svg";

const CompanyFormHeader: React.FC = () => {
  const [openClose, setOpenClose] = useState(false);
  const [openToggle, setOpenToggle] = useState(false);
  const parent = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const data = useDataStore((state) => state.data);
  const isScrolled = headerStore((store) => store.isScrolled);
  const user = userStore((s) => s.user);
  const navigate = useNavigate();

  const handleOpenToggle = () => {
    setOpenToggle(!openToggle);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      const user = auth.currentUser;
      if (!user) {
        navigate("/login");
        console.log("Signed out successfully");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (openClose) {
      document.body.style.overflowY = "hidden";
      document.body.style.height = "120vh";
    } else {
      document.body.style.overflowY = "initial";
      document.body.style.height = "initial";
    }
  }, [openClose]);

  return (
    <div>
      <div className="create-file-wrapper">
        {!isScrolled ? (
          <div className="create-file-container">
            <Link to="/company">
              <div className="create-file">
                <LeftArrow />
                <div>
                  <p>Add new company</p>
                </div>
              </div>
            </Link>
            <div onClick={() => setOpenClose((m) => !m)} className="menu">
              <img src={Menu} alt="" />
            </div>
          </div>
        ) : null}

        <AnimatePresence>
          {openClose && (
            <motion.div
              className="sidebar-backdrop"
              onClick={() => setOpenClose(false)}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            >
              <motion.div
                className="bar"
                onClick={(e: any) => e.stopPropagation()}
                animate={{ right: 0, transitionDelay: "-200ms" }}
                exit={{ right: -300 }}
                initial={{ right: -300 }}
              >
                <div className="header-side-bar">
                  <div
                    className="close-icon"
                    onClick={() => setOpenClose(false)}
                  >
                    <img src={Close} alt="" />
                  </div>

                  <div className="profile-content">
                    <div className="profile">
                      <p>{user?.displayName?.slice(0, 1)}</p>
                    </div>
                    <h6>{user?.displayName}</h6>
                    <p>{user?.email}</p>
                    <Button
                      varient="primary"
                      leftIcon={<Logout />}
                      onClick={handleLogout}
                    >
                      Log out
                    </Button>
                  </div>

                  <div className="border"></div>

                  <div
                    className={`company-drop-down-content  ${
                      openToggle ? "active" : ""
                    }`}
                    onClick={handleOpenToggle}
                  >
                    <div className="media-building">
                      <div>
                        <Building />
                      </div>
                      <Link to="/company">
                        <p>Company</p>
                      </Link>
                    </div>
                    <div>
                      <TopChevronDown
                        className={`drop-down-icon ${
                          openToggle ? "rotate" : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div ref={parent}>
                    {openToggle && (
                      <div className="company-name">
                        {data.map((f, index) => {
                          return (
                            <div
                              key={index}
                              className="link"
                              onClick={() => setOpenClose(false)}
                            >
                              <Link
                                to={`/company/${encodeURIComponent(
                                  f.CompanyName
                                )}`}
                              >
                                <p>{f.CompanyName}</p>
                              </Link>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default CompanyFormHeader;
