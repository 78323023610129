import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./CreatingDataBase.scss";
import Button from "../../components/Button";

const CreatingDataBase: React.FC = () => {
  const [isModalOpen, setModalState] = useState(false);
  const toggleModal = () => setModalState(!isModalOpen);

  return (
    <div className="creating-database-wrapper">
      <div className="mx">
        <div className="creating-database-container">
          <div className="creating-database-content">
            <h3>Creating a DATABASE of SMME's.</h3>
            <p>
              UBUcon has the vision to create the largest Database of
              Registered, Referenced, and compliant SMMEs that can be trusted
              for the successful completion of your project. To be on this
              Database is completely Free.
            </p>
            <Link to="/signup">
              <Button varient="register" onClick={toggleModal}>
                Register For Free
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatingDataBase;
