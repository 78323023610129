import React, { useEffect, useRef, useState } from "react";
import autoAnimate from "@formkit/auto-animate";
import { Link } from "react-router-dom";
import "./Header.scss";
import Button from "../../components/Button";
import logo from "../../assets/logo/ubucon-logo.svg";

const Header: React.FC = () => {
  const [isModalOpen, setModalState] = useState(false);
  const parent = useRef(null);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const toggleModal = () => setModalState(!isModalOpen);
  return (
    <div className="mx">
      <div className="header-wrapper">
        <Link to="/">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </Link>
        <div className="login-buttons">
          <div className="login">
            <Link to="/login">
              <Button varient="secondary" onClick={toggleModal}>
                Login
              </Button>
            </Link>
          </div>

          <Link to="/login">
            <Button varient="primary" onClick={toggleModal}>
              Register now
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
