import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import { DemoData } from "../../Lander/Header/Header";
import NotesIcon from "../../assets/icons/notes-icon.svg";
import { ReactComponent as Close } from "../../assets/icons/close.svg";

const RegisterModal: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <div style={{ marginTop: "79px", paddingTop: "20px" }}>
        <div className="terms-heading-wrapp">
          <div className="terms-heading">
            <img src={NotesIcon} alt="" />
            <h4>Terms & Condition</h4>
          </div>
          <div className="terms-close" onClick={() => navigate(-1)}>
            <Close />
          </div>
        </div>

        <div className="terms-scroll-content">
          {DemoData.map((f, index) => {
            return (
              <div key={index} className="terms-para">
                <h5>{f.title}</h5>
                <p>{f.para}</p>
                <div className="list">
                  <p>{f.contentOne}</p>
                  <p>{f.contentTwo}</p>
                  <p>{f.contentThree}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default RegisterModal;
