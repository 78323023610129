import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./CompanyFiles.scss";
import MoblieHeader from "../../../components/MobileHeader";
import { useDataStore } from "../../../store/dataStore";
import { userStore } from "../../../store/userStore";
import Layout from "../../../components/Layout";
import { IFiles } from "./CompanyFiles";
import Button from "../../../components/Button";
import PdfFile from "../../../assets/icons/pdf.svg";

interface ICompanyFiles {
  id: string;
}

const CompanyFiles: React.FC<ICompanyFiles> = ({ id }) => {
  const data = useDataStore((state) => state.data);
  const user = userStore((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/company");
    }
    if (!user) {
      navigate("/login");
    }
  });

  return (
    <Layout>
      <MoblieHeader />
      {data.map((index) => {
        return (
          <>
            <div className="walmark-img" key={index}>
              <img src={index.bannerImage} alt="bannerImage" />
            </div>
            <div className="walmark-pvt-ltd-content">
              <div className="walmark-para">
                <img src={index.logo} alt="logo" />
                <div>
                  <h6>{index.CompanyName}</h6>
                  <p>Walmart Pvt Ltd</p>
                </div>
              </div>
              <div>
                <Link to="/company-details">
                  <Button varient="primary">View details</Button>
                </Link>
              </div>
            </div>
          </>
        );
      })}
      <div className="files-card">
        {IFiles.map((f, index) => {
          return (
            <Link to={id} key={index}>
              <div className="card">
                <div className="files-img flex-content">
                  <img src={f.image} alt="img" />
                </div>
                <div className="files-end-content">
                  <div className="pdf-file">
                    <img src={PdfFile} alt="" />

                    <div className="content">
                      <h6>{f.title}</h6>
                      <p>{f.item}</p>
                    </div>
                  </div>
                  <div className="new">
                    <p>{f.new}</p>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </Layout>
  );
};

export default CompanyFiles;
