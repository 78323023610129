import React, { useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import "./Hero.scss";
import HeroImgOne from "../../assets/images/hero-img-1.png";
import HeroImgTwo from "../../assets/images/hero-img-2.png";

const Hero: React.FC = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrow: false,
    autoplay: true,
    fade: true,
  };

  const [isModalOpen, setModalState] = useState(false);
  const toggleModal = () => setModalState(!isModalOpen);

  return (
    <div className="hero-wrapper">
      <div className="mx">
        <div className="hero-container">
          <div className="mobile-responsive-heading">
            <h1>Taking Hands to Empower SMME’s</h1>
          </div>
          <div className="hero-content">
            <div className="hero-first-content">
              <Slider {...settings}>
                <div className="image flex-container">
                  <img src={HeroImgOne} alt="" />
                </div>
                <div className="image-two flex-container">
                  <img src={HeroImgTwo} alt="" />
                </div>
              </Slider>
            </div>
            <div className="hero-second-content">
              <div className="h-wrapp">
                <h1>
                  Taking Hands to
                  <br /> Empower SMME’s
                </h1>
              </div>
              <p>
                Small, Medium, and Micro Enterprises (SMMEs) play a pivotal role in the South African 
                construction industry, contributing significantly to its growth and sustainability. 
                These businesses, often characterized by their limited scale and resources, are vital because 
                they provide a multitude of services and products essential to the construction sector. 
                SMMEs form the backbone of the industry by offering specialized skills, materials, and labor, 
                catering to diverse needs, and fostering healthy competition. Their presence not only promotes 
                job creation but also supports local economic development, particularly in underserved areas.                
              </p>
              <p>
                In addition to their economic contributions, SMMEs in the South African construction industry 
                also play a crucial role in advancing social and community development. Many SMMEs in this sector
                are owned by individuals from historically disadvantaged backgrounds, including women and 
                previously marginalized groups. Supporting these businesses not only promotes inclusivity but 
                also empowers communities by providing access to income-generating opportunities.
                In sum, the importance of SMMEs in the South African construction industry extends 
                beyond economic considerations, encompassing social, environmental, and developmental aspects 
                that are integral to the nation's progress.
              </p>
              <div className="flex-btn">
                <Link to="/signup">
                  <Button varient="register" onClick={toggleModal}>
                    Register now
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
