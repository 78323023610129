import SafetyFile from "../../../assets/images/safety-file-img.png";
import PdfFileIcon from "../../../assets/icons/pdf.svg";

export const IBuynow = [
  {
    image: SafetyFile,
    title: "Safety file",
    date: "Created on 04 feb, 2023",
    dollar: "$",
    rate: "24",
  },
];

export const IRecent = [
  {
    icon: PdfFileIcon,
    ProjectName: "Project 1",
    date: "Created on 04 feb, 2023",
  },
  {
    icon: PdfFileIcon,
    ProjectName: "Project 2",
    date: "Created on 04 feb, 2023",
  },
];
