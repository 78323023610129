import React from "react";
import { DemoDataComingsoon } from "./ComingSoon";
import "./ComingSoon.scss";
import Speaker from "../../assets/images/speaker.png";

const ComingSoon: React.FC = () => {
  return (
    <div className="coming-soon-wrapper">
      <div className="mx">
        <div className="coming-soon-container">
          <h4>Coming soon</h4>
          <img src={Speaker} alt="speaker" />
        </div>

        <div className="comingSoon-content">
          {DemoDataComingsoon.map((f, index) => {
            return (
              <div key={index} className="coming-soon-para">
                <img src={f.logo} alt="logo" />
                <h5>{f.title}</h5>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
