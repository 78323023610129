import React from "react";
import { FieldArray } from "formik";
import Input from "../Input";
import Button from "../Button";
import { ReactComponent as Minus } from "../../assets/icons/minus.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";
import { ReactComponent as Delete } from "../../assets/icons/delete.svg";

interface IOpertor {
  constructionVehicle: { file: File; index: number }[];
  setConstructionVehicle: React.Dispatch<
    React.SetStateAction<
      {
        file: File;
        index: number;
      }[]
    >
  >;
  index: number;
}
const Operator: React.FC<IOpertor> = ({
  index,
  constructionVehicle,
  setConstructionVehicle,
}) => {
  return (
    <div>
      <FieldArray name="operators">
        {(arrayHelpers) => (
          <>
            <div className="plant-operator-inputs">
              <div>
                <Input
                  name={`operators[${index}].operatorName`}
                  placeholder="Enter name"
                  type="text"
                  className="inputCom"
                />
              </div>
              <div>
                <Input
                  name={`operators[${index}].operatorNumber`}
                  placeholder="Enter number"
                  type="number"
                  id="number"
                  className="inputCom"
                />
              </div>
              <div className="upload-content">
                {constructionVehicle &&
                constructionVehicle.find((i) => i.index === index) ? (
                  <Button
                    onClick={() => {
                      setConstructionVehicle((c) => {
                        // Filter out the item with the current index
                        arrayHelpers.remove(index);
                        return c.filter((item) => item.index !== index);
                      });
                    }}
                    varient="secondary"
                  >
                    Delete
                    <Delete />
                  </Button>
                ) : (
                  <div>
                    <label
                      htmlFor={`Construction_${index}`}
                      className="custom-file-upload"
                    >
                      <UploadIcon />
                      Upload
                      <input
                        id={`Construction_${index}`}
                        name={`ConstructionVehicle_${index}`}
                        type="file"
                        onChange={(e) => {
                          const files = e.target.files;
                          if (!files || !files.length) return;

                          if (e.target.files !== null) {
                            setConstructionVehicle((c) => [
                              ...c,
                              {
                                file: files[0],
                                index: index,
                              },
                            ]);
                          }
                          const file = files[0];
                          const maxSize = 5 * 1024 * 1024; // 5MB (example limit)
                          if (file.size > maxSize) {
                            alert(
                              "File size exceeds the limit. Please choose a smaller file."
                            );
                            return;
                          }
                        }}
                        accept="application/pdf, image/png"
                        style={{
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </label>
                  </div>
                )}
                <div className="minus">
                  <Minus
                    onClick={() => {
                      setConstructionVehicle((c) => {
                        // Filter out the item with the current index
                        arrayHelpers.remove(index);
                        return c.filter((item) => item.index !== index);
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
};
export default Operator;
