import { MD5 } from "crypto-js";

export const data = {
  merchant_id: "22750706",
  merchant_key: "1z9qrwoshsp6x",
  return_url: "",
  cancel_url: "",
  notify_url: "https://ubuconbackend.onrender.com/payfast/notify",
  name_first: "",
  email_address: "",
  m_payment_id: "",
  amount: "",
  item_name: "",
  signature: "",
};

export const getData = (data: any): string => {
  let pfParamString = "";
  for (let key in data) {
    if (data.hasOwnProperty(key) && key !== "signature") {
      pfParamString += `${key}=${encodeURIComponent(data[key].trim()).replace(/%20/g, "+")}&`;
    }
  }

  // Remove last ampersand
  pfParamString = pfParamString.slice(0, -1);

  return pfParamString;
};

export const generateSignature: (data: any, passPhrase: string | null) => string = (
  data: any,
  passPhrase = null
) => {
  // Create parameter string
  let pfOutput = "";
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      if (data[key] !== "") {
        pfOutput += `${key}=${encodeURIComponent(data[key].trim()).replace(/%20/g, "+")}&`;
      }
    }
  }

  // Remove last ampersand
  let getString = pfOutput.slice(0, -1);

  if (passPhrase !== null) {
    getString += `&passphrase=${encodeURIComponent(passPhrase.trim()).replace(/%20/g, "+")}`;
  }

  return MD5(getString).toString();
};
