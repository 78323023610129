import React from "react";
import "./Registration.scss";
import RegistrationImg from "../../assets/images/registration-img.png";

const Registration: React.FC = () => {
  return (
    <>
      <div className="registration-wrapper">
        <div className="mx">
          <div className="registration-heading">
            <h4>Registration Requirements</h4>
          </div>
          <div className="registration-container">
            <div className="registration-img">
              <img src={RegistrationImg} alt="" />
            </div>
            <div className="content">
              <ul>
                <li>Valid company registration documents</li>
                <li>Valid BEE certificate</li>
                <li>Valid VAT registration documents - optional</li>
              </ul>
            </div>
            <div className="content">
              <ul>
                <li>Valid tax clearance certificate</li>
                <li>Valid CIDB certificate - Optional</li>
                <li>Valid PAYE registration certificate</li>
                <li>ID document of all directors</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="fingertip">
        <h3>Hassle free creation of a Safety File at your fingertips.</h3>
      </div>
    </>
  );
};

export default Registration;
