export const provinceData = [
  {
    title: "List of municipalities in the Eastern Cape",
    municipality: [
      "Alfred Nzo District Municipality",
      "Amathole District Municipality",
      "Buffalo City Metropolitan Municipality",
      "Chris Hani District Municipality",
      "Joe Gqabi District Municipality",
      "	Nelson Mandela Bay Metropolitan Municipality",
      "OR Tambo District Municipality",
      "Sarah Baartman District Municipality",
      "Amahlathi Local Municipality",
      "	Blue Crane Route Local Municipality",
      "	Buffalo City Metropolitan Municipality",
      "	Dr Beyers Naudé Local Municipality",
      "Elundini Local Municipality",
      "	Emalahleni Local Municipality",
      "Engcobo Local Municipality",
      "	Enoch Mgijima Local Municipality",
      "Great Kei Local Municipality",
      "	Ingquza Hill Local Municipality",
      "	Intsika Yethu Local Municipality",
      "	Inxuba Yethemba Local Municipality",
      "King Sabata Dalindyebo Local Municipality",
      "	Kou-Kamma Local Municipality",
      "	Kouga Local Municipality",
      "	Makana Local Municipality",
      "Matatiele Local Municipality",
      "	Mbhashe Local Municipality",
      "Winnie Madikizela-Mandela Local Municipality",
      "Mhlontlo Local Municipality",
      "Mnquma Local Municipality",
      "	Ndlambe Local Municipality",
      "	Nelson Mandela Bay Metropolitan Municipality",
      "	Ngqushwa Local Municipality",
      "Ntabankulu Local Municipality",
      "	Nyandeni Local Municipality",
      "	Port St Johns Local Municipality",
      "	Raymond Mhlaba Local Municipality",
      "Sakhisizwe Local Municipality",
      "	Senqu Local Municipality",
      "	Sundays River Valley Local Municipality",
      "	Umzimvubu Local Municipality",
      "Walter Sisulu Local Municipality",
    ],
  },
  {
    title: "List of municipalities in the Free State",
    municipality: [
      "	Fezile Dabi District Municipality",
      "	Lejweleputswa District Municipality",
      "	Mangaung Metropolitan Municipality",
      "Thabo Mofutsanyana District Municipality",
      "Xhariep District Municipality",
      "	Dihlabeng Local Municipality",
      "Kopanong Local Municipality",
      "Letsemeng Local Municipality",
      "	Mafube Local Municipality",
      "Maluti-a-Phofung Local Municipality",
      "	Mangaung Metropolitan Municipality	",
      "Mantsopa Local Municipality",
      "Masilonyana Local Municipality",
      "	Matjhabeng Local Municipality",
      "Metsimaholo Local Municipality",
      "Mohokare Local Municipality",
      "Moqhaka Local Municipality",
      "Nala Local Municipality",
      "	Ngwathe Local Municipality",
      "	Nketoana Local Municipality",
      "Phumelela Local Municipality",
      "	Setsoto Local Municipality",
      "	Tokologo Local Municipality",
      "	Tswelopele Local Municipality",
    ],
  },
  {
    title: "List of municipalities in the Gauteng",
    municipality: [
      "	City of Ekurhuleni Metropolitan Municipality",
      "City of Johannesburg Metropolitan Municipality",
      "City of Tshwane Metropolitan Municipality",
      "Sedibeng District Municipality",
      "West Rand District Municipality",
      "	City of Ekurhuleni Metropolitan Municipality",
      "City of Johannesburg Metropolitan Municipality	",
      "	City of Tshwane Metropolitan Municipality",
      "	Lesedi Local Municipality",
      "	Emfuleni Local Municipality",
      "Merafong City Local Municipality",
      "	Midvaal Local Municipality",
      "Mogale City Local Municipality",
      "Rand West City Local Municipality",
    ],
  },
  {
    title: "List of municipalities in KwaZulu-Natal",
    municipality: [
      "Amajuba District Municipality",
      "eThekwini Metropolitan Municipality",
      "Harry Gwala District Municipality",
      "iLembe District Municipality",
      "King Cetshwayo District Municipality",
      "	Ugu District Municipality",
      "	uMgungundlovu District Municipality",
      "	uMkhanyakude District Municipality",
      "	uMzinyathi District Municipality",
      "	uThukela District Municipality",
      "Zululand District Municipality",
      "Abaqulusi Local Municipality",
      "Alfred Duma Local Municipality",
      "Big Five Hlabisa Local Municipality",
      "Dannhauser Local Municipality",
      "Dr Nkosazana Dlamini Zuma Local Municipality",
      "eDumbe Local Municipality",
      "eThekwini Metropolitan Municipality",
      "Greater Kokstad Local Municipality",
      "Impendle Local Municipality",
      "Inkosi Langalibalele Local Municipality",
      "Jozini Local Municipality",
      "KwaDukuza Local Municipality",
      "Mandeni Local Municipality",
      "Maphumulo Local Municipality",
      "Mkhambathini Local Municipality",
      "Mpofana Local Municipality",
      "Msinga Local Municipality",
      "Msunduzi Local Municipality",
      "Mtubatuba Local Municipality",
      "Ndwedwe Local Municipality",
      "Newcastle Local Municipality",
      "Nkandla Local Municipality",
      "Nongoma Local Municipality",
      "Nqutu Local Municipality",
      "Okhahlamba Local Municipality",
      "Ray Nkonyeni Local Municipality",
      "Richmond Local Municipality",
      "Ubuhlebezwe Local Municipality",
      "Ulundi Local Municipality",
      "uMdoni Local Municipality",
      "uMfolozi Local Municipality",
      "uMhlabuyalingana Local Municipality",
      "uMhlathuze Local Municipality",
      "uMlalazi Local Municipality",
      "uMngeni Local Municipality",
      "uMshwathi Local Municipality",
      "uMuziwabantu Local Municipality",
      "Umvoti Local Municipality",
      "Umzumbe Local Municipality",
      "uPhongolo Local Municipality",
    ],
  },
  {
    title: "List of municipalities in Limpopo",
    municipality: [
      "Capricorn District Municipality",
      "Mopani District Municipality",
      "Sekhukhune District Municipality",
      "Vhembe District Municipality",
      "Waterberg District Municipality",
      "Ba-Phalaborwa Local Municipality",
      "Bela-Bela Local Municipality",
      "Blouberg Local Municipality",
      "Collins Chabane Local Municipality",
      "Elias Motsoaledi Local Municipality",
      "Ephraim Mogale Local Municipality",
      "Fetakgomo Tubatse Local Municipality",
      "Greater Giyani Local Municipality",
      "Greater Letaba Local Municipality",
      "Greater Tzaneen Local Municipality",
      "Lepelle-Nkumpi Local Municipality",
      "Lephalale Local Municipality",
      "Makhado Local Municipality",
      "Makhuduthamaga Local Municipality",
      "Maruleng Local Municipality",
      "Modimolle–Mookgophong Local Municipality",
      "Mogalakwena Local Municipality",
      "Molemole Local Municipality",
      "Musina Local Municipality",
      "Polokwane Local Municipality",
      "Thabazimbi Local Municipality",
      "Thulamela Local Municipality",
    ],
  },
  {
    title: "List of municipalities in Mpumalanga",
    municipality: [
      "Ehlanzeni District Municipality",
      "Gert Sibande District Municipality",
      "Nkangala District Municipality",
      "Albert Luthuli Local Municipality",
      "Bushbuckridge Local Municipality",
      "Dipaleseng Local Municipality",
      "Dr JS Moroka Local Municipality",
      "Emakhazeni Local Municipality",
      "Emalahleni Local Municipality",
      "Govan Mbeki Local Municipality",
      "Lekwa Local Municipality",
      "Mbombela Local Municipality",
      "Mkhondo Local Municipality",
      "Msukaligwa Local Municipality",
      "Nkomazi Local Municipality",
      "Pixley ka Seme Local Municipality",
      "Steve Tshwete Local Municipality",
      "Thaba Chweu Local Municipality",
      "Thembisile Hani Local Municipality",
      "Victor Khanye Local Municipality",
    ],
  },
  {
    title: "List of municipalities in the North West",
    municipality: [
      "Bojanala Platinum District Municipality",
      "Dr Kenneth Kaunda District Municipality",
      "Dr Ruth Segomotsi Mompati District Municipality",
      "Ngaka Modiri Molema District Municipality",
      "City of Matlosana Local Municipality",
      "Ditsobotla Local Municipality",
      "Greater Taung Local Municipality",
      "JB Marks Local Municipality",
      "Kagisano-Molopo Local Municipality",
      "Kgetlengrivier Local Municipality",
      "Lekwa-Teemane Local Municipality",
      "Madibeng Local Municipality",
      "Mahikeng Local Municipality",
      "Mamusa Local Municipality",
      "Maquassi Hills Local Municipality",
      "Moretele Local Municipality",
      "Moses Kotane Local Municipality",
      "Naledi Local Municipality",
      "Ramotshere Moiloa Local Municipality",
      "Ratlou Local Municipality",
      "Rustenburg Local Municipality",
      "Tswaing Local Municipality",
    ],
  },
  {
    title: "List of municipalities in the Northern Cape",
    municipality: [
      "Frances Baard District Municipality",
      "John Taolo Gaetsewe District Municipality",
      "Namakwa District Municipality",
      "Pixley ka Seme District Municipality",
      "ZF Mgcawu District Municipality",
      "!Kheis Local Municipality",
      "Dawid Kruiper Local Municipality",
      "Dikgatlong Local Municipality",
      "Emthanjeni Local Municipality",
      "Ga-Segonyana Local Municipality",
      "Gamagara Local Municipality",
      "Hantam Local Municipality",
      "Joe Morolong Local Municipality",
      "Kai !Garib Local Municipality",
      "Kamiesberg Local Municipality",
      "Kareeberg Local Municipality",
      "Karoo Hoogland Local Municipality",
      "Kgatelopele Local Municipality",
      "Khâi-Ma Local Municipality",
      "Magareng Local Municipality",
      "Nama Khoi Local Municipality",
      "Phokwane Local Municipality",
      "Renosterberg Local Municipality",
      "Richtersveld Local Municipality",
      "Siyancuma Local Municipality",
      "Siyathemba Local Municipality",
      "Sol Plaatje Local Municipality",
      "Thembelihle Local Municipality",
      "Tsantsabane Local Municipality",
    ],
  },
  {
    title: "List of municipalities in the Western Cape",
    municipality: [
      "Cape Winelands District Municipality",
      "Central Karoo District Municipality",
      "City of Cape Town Metropolitan Municipality",
      "Garden Route District Municipality",
      "Overberg District Municipality",
      "West Coast District Municipality",
      "Beaufort West Local Municipality",
      "Bergrivier Local Municipality",
      "Bitou Local Municipality",
      "Breede Valley Local Municipality",
      "Cape Agulhas Local Municipality",
      "Cederberg Local Municipality",
      "City of Cape Town Metropolitan Municipality",
      "Drakenstein Local Municipality",
      "George Local Municipality",
      "Hessequa Local Municipality",
      "Kannaland Local Municipality",
      "Knysna Local Municipality",
      "Laingsburg Local Municipality",
      "Langeberg Local Municipality",
      "Matzikama Local Municipality",
      "Mossel Bay Local Municipality",
      "Oudtshoorn Local Municipality",
      "Overstrand Local Municipality",
      "Prince Albert Local Municipality",
      "Saldanha Bay Local Municipality",
      "Stellenbosch Local Municipality",
      "Swartland Local Municipality",
      "Swellendam Local Municipality",
      "Theewaterskloof Local Municipality",
      "Witzenberg Local Municipality",
    ],
  },
];
