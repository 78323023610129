import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import autoAnimate from "@formkit/auto-animate";
import { FirebaseError } from "firebase/app";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  AuthErrorCodes,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import "./Login.scss";
import { auth } from "../../utils/firebase";
import Input from "../../components/Input";
import AuthLayoutPage from "../../layouts/AuthLayoutPage";
import Button from "../../components/Button";
import { userStore } from "../../store/userStore";
import Logo from "../../assets/logo/ubu-con-logo-2.png";
import { ReactComponent as EyeIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as Google } from "../../assets/icons/google-svg.svg";
import { ReactComponent as HideEyeIcon } from "../../assets/icons/hide-icon.svg";

const initialValues = { email: "", password: "", checkbox: "" };

const Login: React.FC = () => {
  const navigate = useNavigate();
  const updateUser = userStore((state) => state.updateUser);
  const updateFetching = userStore((state) => state.updateFetching);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const parent = useRef(null);

  //auto animate

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    return onAuthStateChanged(auth, (data) => {
      if (data) {
        updateUser(data);
        setTimeout(() => {
          updateFetching(false);
          navigate("/company");
        }, 0);
      } else {
        setTimeout(() => {
          updateFetching(false);
          navigate("/login");
        }, 0);
      }
    });

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    checkbox: Yup.string().required("Accept terms&condition"),
  });

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const dataStore = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      navigate("/company");
      console.log("dataStore", dataStore);
    } catch (error) {
      console.log(error);
      if (error instanceof FirebaseError) {
        if (error.code === AuthErrorCodes.INVALID_PASSWORD) {
          setErrorMessage("Invalid password");
        } else if (error.code === AuthErrorCodes.USER_DELETED) {
          setErrorMessage("User not found");
        }
      }
    }
  };

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleGoogleMethod = async () => {
    try {
      const Provider = new GoogleAuthProvider();
      const data = await signInWithPopup(auth, Provider);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleBlur, isSubmitting }) => (
          <Form>
            <AuthLayoutPage>
              <div className="login-container">
                <div ref={parent}>
                  {errorMessage && (
                    <div className="error-window">
                      <p>{errorMessage}</p>
                    </div>
                  )}
                </div>
                <div className="logo">
                  <img src={Logo} alt="logo" />
                </div>
                <div className="login-wrapper flex-content">
                  <div>
                    <div className="login-text">Log in</div>

                    <Input
                      name="email"
                      type="email"
                      placeholder="Enter your email"
                      label="E-mail"
                      onBlur={handleBlur}
                    />

                    <Input
                      name="password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      label="Password"
                      icon={
                        showPassword ? (
                          <EyeIcon
                            width={14}
                            height={14}
                            cursor="pointer"
                            onClick={handleTogglePassword}
                          />
                        ) : (
                          <HideEyeIcon
                            width={14}
                            height={14}
                            cursor="pointer"
                            onClick={handleTogglePassword}
                          />
                        )
                      }
                    />

                    <div className="forgot-password">
                      <Link to="/forgot">Forgot password?</Link>
                    </div>
                    <div className="terms-condition">
                      <div className="terms">
                        <Field type="checkbox" name="checkbox" />
                        <Link to="/terms&condition">
                          <p>Terms & Conditions...</p>
                        </Link>
                      </div>
                    </div>
                    <Button
                      varient="primary"
                      type="submit"
                      disabled={isSubmitting}
                      style={{ opacity: isSubmitting ? "0.6" : "" }}
                    >
                      {isSubmitting ? "Logining..." : "Log in"}
                    </Button>

                    <div className="line-border">
                      <div className="border"></div>
                      <p>or</p>
                      <div className="border"></div>
                    </div>
                    <div
                      className="google-content"
                      onClick={handleGoogleMethod}
                    >
                      <Google />
                      <p>Sign in with google</p>
                    </div>
                    <div className="sign-up-content">
                      <p>Don’t you have an account?</p>
                      <Link to="/signup">Sign up</Link>
                    </div>
                  </div>
                </div>
              </div>
            </AuthLayoutPage>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Login;
