export const DemoData = [
  {
    title: "POPI Disclaimer",
    para: "We respect your right to privacy and therefore aim to ensure that we comply with the legal requirement of the POPI Act which regulates the manner in which we collect, process, store, share and destroy any personal information which you have provided to us.",
  },
  {
    title: "Why we collect Personal Information:",
    para: "We collect personal information in order to liaise with you telephonically, via our website or email so that we may:",
    contentOne: "1. Respond to any query or comment received from you;",
    contentTwo: "2. Inform you of new offers;",
    contentThree:
      "3. Enable us to process, validate and verify information and requests for the supply of goods and/or services;",
  },
  {
    title: "Processing of Information",
    para: "We respect your right to privacy and therefore aim to ensure that we comply with the legal requirement of the POPI Act which regulates the manner in which we collect, process, store, share and destroy any personal information which you have provided to us.",
  },
  {
    title: "Security Measures",
    para: "Respond to any query or comment received from you",
  },
  {
    title: "Your rights",
    para: "Respond to any query or comment received from you",
  },
];
