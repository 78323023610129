import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore/lite";
import { db } from "../../utils/firebase";
import { ICompany } from "../../utils/types";
import SafetyFile from "./SafetyFile";
import EditCompanyDetailForm from "./EditCompanyForm";
import Loading from "../../components/Loading";
import CompanyFiles from "./Home";
import DocumentPage from "./Document";

const CompanyDetailsPage: React.FC = () => {
  const [companyData, setCompanyData] = useState<ICompany | null>(null);
  const { company_name } = useParams();

  const handleGetData = useCallback(async () => {
    try {
      const q = query(
        collection(db, "companyInfo"),
        where("CompanyName", "==", decodeURIComponent(company_name as string))
      );

      const querySnapshot = await getDocs(q);

      const fetchedData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as Omit<ICompany, "id">),
      }));
      if (fetchedData.length) {
        setCompanyData(fetchedData[0]);
      }
    } catch (error) {
      console.error("Error fetching Firestore data:", error);
    }
  }, [company_name]);

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  if (!companyData)
    return (
      <div>
        <Loading />
      </div>
    );

  return (
    <div>
      <Routes>
        <Route path="/" element={<CompanyFiles {...companyData} />} />
        <Route
          path="/safety-files"
          element={<DocumentPage {...companyData} />}
        />
        <Route
          path="/safety-files/buy/"
          element={<SafetyFile {...companyData} />}
        />
        <Route
          path="/edit"
          element={
            <EditCompanyDetailForm
              isModalOpen={false}
              toggleModal={function (): void {
                throw new Error("Function not implemented.");
              }}
              existingValues={{ ...companyData }}
              {...companyData}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default CompanyDetailsPage;
