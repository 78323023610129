import React, { Fragment, useEffect, useState } from "react";
import "./SplashScreen.scss";
import { ReactComponent as Logo } from "../../assets/icons/ubuconlogo.svg";

const SplashScreen: React.FC = () => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(false), 2100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <div className="mx">
      {show && (
        <Fragment>
          <div className="splashscreen">
            <div className="circlelogo">
              <div className="dot1">
                <div className="circle"></div>
              </div>
              <div className="dot2">
                <div className="circle"></div>
              </div>
              <div className="dot3">
                <div className="circle"></div>
              </div>
              <div className="dot4">
                <div className="circle"></div>
              </div>
            </div>
            <div className="logo">
              <Logo />
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default SplashScreen;
